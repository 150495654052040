export default function Island({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="88"
            viewBox="0 0 396 88"
            width="396"
            xmlns="http://www.w3.org/2000/svg"
        >
            <filter
                id="a"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
                height="88"
                width="396"
                x="0"
                y="0"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0509804 0 0 0 0 0.0392157 0 0 0 0 0.172549 0 0 0 0.08 0"
                />
                <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_2131_1363" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="2.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0509804 0 0 0 0 0.0392157 0 0 0 0 0.172549 0 0 0 0.12 0"
                />
                <feBlend in2="effect1_dropShadow_2131_1363" mode="normal" result="effect2_dropShadow_2131_1363" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_2131_1363" mode="normal" result="shape" />
            </filter>
            <g filter="url(#a)">
                <rect fill="#161616" height="72" rx="36" width="380" x="8" y="4" />
                <rect height="71" rx="35.5" stroke="#444" width="379" x="8.5" y="4.5" />
                <g fill="#ececec">
                    <path d="m238.801 31.4694v-.0944c.007-.6165.068-1.1071.184-1.4716.12-.3646.289-.6596.507-.885.219-.2254.483-.4308.791-.6165.199-.1259.378-.2668.537-.4225.159-.1558.285-.3348.378-.537.092-.2022.139-.4259.139-.6711 0-.295-.07-.5502-.209-.7657-.139-.2154-.325-.3811-.557-.4971-.228-.1193-.484-.179-.765-.179-.256 0-.499.053-.731.1591s-.424.2718-.577.4972c-.152.222-.24.5087-.263.86h-1.512c.023-.5966.174-1.1003.453-1.5113.278-.4143.646-.7275 1.103-.9397.461-.2121.97-.3181 1.527-.3181.609 0 1.143.1143 1.6.343.458.2254.812.5419 1.064.9496.256.4043.383.8766.383 1.4169 0 .3712-.058.7059-.174 1.0042-.116.295-.282.5585-.497.7905-.212.232-.467.4375-.766.6165-.281.1757-.51.358-.686.5469-.172.1889-.298.4126-.378.6711-.079.2586-.122.5784-.129.9596v.0944zm.751 3.0227c-.272 0-.505-.0961-.701-.2883-.196-.1956-.293-.4309-.293-.706 0-.2718.097-.5038.293-.696.196-.1955.429-.2933.701-.2933.268 0 .5.0978.696.2933.199.1922.298.4242.298.696 0 .1823-.046.3497-.139.5022-.089.1491-.209.2684-.358.3579s-.315.1342-.497.1342z" />
                    <path d="m235.176 24.2158v10.1818h-1.487v-10.1818z" />
                    <path d="m230.2 31.2307v-4.4695h1.492v7.6364h-1.462v-1.3224h-.079c-.176.4076-.458.7473-.845 1.0191-.385.2685-.864.4027-1.437.4027-.491 0-.925-.1077-1.303-.3231-.374-.2188-.669-.5419-.885-.9695-.212-.4275-.318-.9562-.318-1.5859v-4.8573h1.487v4.6783c0 .5204.144.9347.432 1.2429s.663.4624 1.124.4624c.278 0 .555-.0696.83-.2089.278-.1392.509-.3496.691-.6313.186-.2818.277-.6397.273-1.0739z" />
                    <path d="m223.829 26.7613v1.1931h-4.315v-1.1931zm-3.132 7.6363v-8.5213c0-.4773.105-.8733.313-1.1882.209-.3182.486-.5552.831-.7109.344-.1591.719-.2387 1.123-.2387.299 0 .554.0249.766.0746.212.0464.369.0895.472.1293l-.348 1.2031c-.069-.0199-.159-.0431-.268-.0696-.11-.0298-.242-.0448-.398-.0448-.361 0-.62.0895-.776.2685-.152.179-.228.4375-.228.7756v8.3224z" />
                    <path d="m211.555 37.2613v-10.5h1.451v1.2379h.125c.086-.1591.21-.343.372-.5518.163-.2088.388-.3911.677-.5469.288-.1591.669-.2386 1.143-.2386.616 0 1.167.1557 1.651.4673.483.3115.863.7606 1.138 1.3473.278.5866.418 1.2926.418 2.1179s-.138 1.5329-.413 2.1229c-.275.5866-.653 1.039-1.134 1.3572-.48.3149-1.029.4723-1.645.4723-.464 0-.844-.0779-1.139-.2337-.291-.1557-.52-.338-.686-.5468-.165-.2088-.293-.3944-.382-.5568h-.09v4.0518zm1.456-6.6818c0 .5369.078 1.0076.234 1.4119.156.4044.381.7209.676.9496.295.2254.656.3381 1.084.3381.444 0 .815-.1177 1.113-.353.299-.2387.524-.5618.677-.9695.155-.4077.233-.8667.233-1.3771 0-.5038-.076-.9562-.228-1.3573-.15-.401-.375-.7175-.677-.9495-.298-.232-.671-.3481-1.118-.3481-.431 0-.796.1111-1.094.3331-.295.2221-.519.532-.671.9297-.153.3978-.229.8618-.229 1.3921z" />
                    <path d="m209.555 24.2158v10.1818h-1.487v-10.1818z" />
                    <path d="m203.143 34.5518c-.753 0-1.401-.1608-1.944-.4823-.541-.3248-.958-.7805-1.253-1.3672-.292-.5899-.438-1.281-.438-2.0731 0-.7822.146-1.4716.438-2.0682.295-.5966.706-1.0623 1.233-1.397.53-.3348 1.15-.5021 1.859-.5021.431 0 .849.0712 1.253.2137.404.1426.767.3663 1.089.6712.321.3049.575.701.76 1.1882.186.4839.279 1.0722.279 1.7649v.527h-6.07v-1.1136h4.613c0-.3911-.079-.7375-.238-1.0391-.16-.3049-.383-.5452-.672-.7209-.285-.1756-.619-.2635-1.004-.2635-.417 0-.782.1028-1.094.3083-.308.2022-.547.4673-.716.7954-.165.3249-.248.6778-.248 1.059v.87c0 .5104.089.9446.268 1.3026.183.3579.436.6314.761.8203.325.1856.704.2784 1.139.2784.281 0 .538-.0398.77-.1193.232-.0829.433-.2055.602-.3679s.298-.3629.387-.6016l1.407.2536c-.112.4143-.314.7772-.606 1.0887-.288.3083-.651.5486-1.089.7209-.434.1691-.93.2536-1.486.2536z" />
                    <path d="m192.916 29.8635v4.5341h-1.486v-10.1818h1.466v3.7884h.095c.179-.411.452-.7375.82-.9794.368-.242.848-.363 1.442-.363.523 0 .981.1078 1.372.3232.394.2154.699.5369.915.9645.218.4242.328.9545.328 1.5909v4.8572h-1.487v-4.6782c0-.5602-.144-.9943-.432-1.3026-.289-.3115-.69-.4673-1.203-.4673-.352 0-.667.0746-.945.2237-.275.1492-.492.3679-.651.6563-.156.285-.234.6297-.234 1.034z" />
                    <path d="m186.08 26.7612v1.1932h-4.171v-1.1932zm-3.052-1.8296h1.486v7.2238c0 .2883.043.5054.129.6512.086.1426.197.2403.333.2934.14.0497.29.0745.453.0745.119 0 .224-.0082.313-.0248s.159-.0298.209-.0398l.268 1.228c-.086.0331-.209.0663-.368.0994-.159.0365-.358.0564-.596.0597-.391.0066-.756-.063-1.094-.2088-.338-.1459-.611-.3712-.82-.6762-.209-.3049-.313-.6877-.313-1.1484z" />
                    <path d="m175.635 29.8636v4.5341h-1.487v-7.6364h1.427v1.2429h.095c.175-.4044.45-.7292.825-.9744.378-.2453.853-.3679 1.427-.3679.52 0 .976.1093 1.367.3281.391.2154.694.5369.91.9645.215.4275.323.9562.323 1.5859v4.8573h-1.487v-4.6783c0-.5535-.144-.986-.432-1.2976-.289-.3149-.685-.4723-1.188-.4723-.345 0-.652.0746-.92.2237-.265.1492-.476.3679-.632.6563-.152.285-.228.6297-.228 1.0341z" />
                    <path d="m169.223 34.5518c-.753 0-1.401-.1608-1.944-.4823-.54-.3248-.958-.7805-1.253-1.3672-.292-.5899-.438-1.281-.438-2.0731 0-.7822.146-1.4716.438-2.0682.295-.5966.706-1.0623 1.233-1.397.53-.3348 1.15-.5021 1.859-.5021.431 0 .849.0712 1.253.2137.404.1426.767.3663 1.089.6712.321.3049.575.701.761 1.1882.185.4839.278 1.0722.278 1.7649v.527h-6.07v-1.1136h4.613c0-.3911-.079-.7375-.238-1.0391-.159-.3049-.383-.5452-.672-.7209-.285-.1756-.619-.2635-1.004-.2635-.417 0-.782.1028-1.094.3083-.308.2022-.546.4673-.715.7954-.166.3249-.249.6778-.249 1.059v.87c0 .5104.089.9446.268 1.3026.183.3579.436.6314.761.8203.325.1856.704.2784 1.139.2784.281 0 .538-.0398.77-.1193.232-.0829.433-.2055.602-.3679s.298-.3629.388-.6016l1.406.2536c-.112.4143-.314.7772-.606 1.0887-.288.3083-.651.5486-1.089.7209-.434.1691-.93.2536-1.486.2536z" />
                    <path d="m164.369 26.7612v1.1932h-4.171v-1.1932zm-3.052-1.8296h1.486v7.2238c0 .2883.043.5054.129.6512.087.1426.198.2403.333.2934.14.0497.29.0745.453.0745.119 0 .224-.0082.313-.0248.09-.0166.159-.0298.209-.0398l.268 1.228c-.086.0331-.208.0663-.368.0994-.159.0365-.358.0564-.596.0597-.391.0066-.756-.063-1.094-.2088-.338-.1459-.611-.3712-.82-.6762-.209-.3049-.313-.6877-.313-1.1484z" />
                    <path d="m153.924 29.8636v4.5341h-1.487v-7.6364h1.427v1.2429h.095c.175-.4044.45-.7292.825-.9744.378-.2453.853-.3679 1.427-.3679.52 0 .976.1093 1.367.3281.391.2154.694.5369.91.9645.215.4275.323.9562.323 1.5859v4.8573h-1.487v-4.6783c0-.5535-.144-.986-.432-1.2976-.288-.3149-.685-.4723-1.188-.4723-.345 0-.652.0746-.92.2237-.265.1492-.476.3679-.631.6563-.153.285-.229.6297-.229 1.0341z" />
                    <path d="m147.218 34.5518c-.716 0-1.34-.1641-1.874-.4922s-.948-.7872-1.243-1.3771c-.295-.59-.442-1.2794-.442-2.0682 0-.7922.147-1.4849.442-2.0781.295-.5933.709-1.054 1.243-1.3821.534-.3282 1.158-.4922 1.874-.4922s1.341.164 1.875.4922c.533.3281.948.7888 1.243 1.3821.295.5932.442 1.2859.442 2.0781 0 .7888-.147 1.4782-.442 2.0682-.295.5899-.71 1.049-1.243 1.3771-.534.3281-1.159.4922-1.875.4922zm.005-1.2479c.464 0 .849-.1226 1.154-.3679s.53-.5717.676-.9794c.149-.4077.224-.8568.224-1.3473 0-.4872-.075-.9347-.224-1.3423-.146-.411-.371-.7408-.676-.9894-.305-.2485-.69-.3728-1.154-.3728-.467 0-.855.1243-1.163.3728-.305.2486-.532.5784-.681.9894-.146.4076-.219.8551-.219 1.3423 0 .4905.073.9396.219 1.3473.149.4077.376.7341.681.9794.308.2453.696.3679 1.163.3679z" />
                    <path d="m139.275 34.5518c-.739 0-1.375-.1674-1.909-.5022-.53-.338-.938-.8037-1.223-1.397-.285-.5932-.428-1.2727-.428-2.0383 0-.7756.146-1.46.438-2.0533.292-.5966.703-1.0623 1.233-1.397.53-.3348 1.155-.5021 1.874-.5021.58 0 1.097.1077 1.551.3231.454.2121.821.5104 1.099.8949.282.3845.449.8336.502 1.3473h-1.447c-.079-.3579-.261-.6662-.546-.9247-.282-.2585-.66-.3878-1.134-.3878-.414 0-.777.1094-1.089.3281-.308.2155-.548.5237-.721.9247-.172.3978-.258.8684-.258 1.412 0 .5568.084 1.0374.253 1.4417.169.4044.408.7176.716.9397.312.222.678.3331 1.099.3331.282 0 .537-.0514.766-.1542.232-.106.426-.2568.581-.4524.159-.1955.27-.4308.333-.7059h1.447c-.053.4938-.214.9346-.482 1.3224-.269.3878-.628.6927-1.079.9148-.447.222-.973.3331-1.576.3331z" />
                    <path d="m130.57 28.6256-1.348.2387c-.056-.1724-.145-.3364-.268-.4922-.119-.1558-.282-.2834-.487-.3828-.206-.0995-.463-.1492-.771-.1492-.421 0-.772.0945-1.054.2834-.281.1856-.422.4259-.422.7209 0 .2552.094.4607.283.6165.189.1557.494.2834.915.3828l1.213.2784c.703.1624 1.226.4126 1.571.7507s.517.7772.517 1.3175c0 .4574-.133.865-.398 1.223-.262.3546-.628.633-1.099.8352-.467.2022-1.009.3033-1.625.3033-.855 0-1.553-.1823-2.093-.5469-.541-.3679-.872-.8899-.995-1.566l1.437-.2188c.09.3745.274.6579.552.8501.278.189.641.2834 1.089.2834.487 0 .876-.1011 1.168-.3032.292-.2055.438-.4558.438-.7508 0-.2386-.09-.4391-.269-.6015-.175-.1624-.446-.2851-.81-.3679l-1.293-.2834c-.712-.1624-1.239-.4209-1.581-.7756-.338-.3546-.507-.8037-.507-1.3473 0-.4507.126-.8451.378-1.1832s.6-.6016 1.044-.7905c.444-.1922.953-.2883 1.526-.2883.826 0 1.475.1789 1.949.5369.474.3546.787.8303.94 1.4268z" />
                    <path d="m121.361 34.3976v-7.6364h1.487v7.6364zm.751-8.8146c-.259 0-.481-.0862-.666-.2586-.183-.1756-.274-.3844-.274-.6264 0-.2452.091-.4541.274-.6264.185-.1757.407-.2635.666-.2635.258 0 .479.0878.661.2635.186.1723.278.3812.278.6264 0 .242-.092.4508-.278.6264-.182.1724-.403.2586-.661.2586z" />
                    <path d="m114.426 29.8635v4.5341h-1.487v-10.1818h1.467v3.7884h.094c.179-.411.453-.7375.821-.9794.368-.242.848-.363 1.441-.363.524 0 .981.1078 1.373.3232.394.2154.699.5369.914.9645.219.4242.328.9545.328 1.5909v4.8572h-1.486v-4.6782c0-.5602-.144-.9943-.433-1.3026-.288-.3115-.689-.4673-1.203-.4673-.351 0-.666.0746-.944.2237-.275.1492-.492.3679-.652.6563-.155.285-.233.6297-.233 1.034z" />
                    <path d="m111.131 26.7612v1.1932h-4.171v-1.1932zm-3.053-1.8296h1.487v7.2238c0 .2883.043.5054.129.6512.086.1426.197.2403.333.2934.139.0497.29.0745.453.0745.119 0 .223-.0082.313-.0248.089-.0166.159-.0298.209-.0398l.268 1.228c-.086.0331-.209.0663-.368.0994-.159.0365-.358.0564-.596.0597-.392.0066-.756-.063-1.094-.2088-.338-.1459-.612-.3712-.82-.6762-.209-.3049-.314-.6877-.314-1.1484z" />
                    <path d="m97.9561 34.5468c-.6164 0-1.1666-.1574-1.6505-.4723-.4806-.3182-.8585-.7706-1.1335-1.3573-.2718-.5899-.4077-1.2975-.4077-2.1228s.1375-1.5313.4126-2.1179c.2784-.5867.6596-1.0358 1.1435-1.3473.4839-.3116 1.0324-.4674 1.6456-.4674.4739 0 .8551.0796 1.1435.2387.2916.1558.517.338.6761.5469.1624.2088.2883.3927.3783.5518h.089v-3.7834h1.487v10.1818h-1.452v-1.1882h-.124c-.09.1624-.2192.348-.3883.5568-.1657.2089-.3944.3911-.686.5469-.2917.1558-.6695.2337-1.1336.2337zm.3282-1.2678c.4275 0 .7888-.1127 1.0838-.338.2983-.2287.5236-.5453.6759-.9496.156-.4044.234-.875.234-1.4119 0-.5303-.076-.9944-.229-1.3921-.1523-.3977-.376-.7076-.671-.9297-.295-.222-.6595-.3331-1.0937-.3331-.4475 0-.8203.116-1.1186.348s-.5237.5486-.6762.9496c-.1491.4011-.2237.8535-.2237 1.3573 0 .5104.0762.9694.2287 1.3771s.3778.7308.6761.9694c.3016.2354.6729.353 1.1137.353z" />
                    <path d="m88.2168 29.8636v4.5341h-1.4865v-7.6364h1.4268v1.2429h.0945c.1757-.4044.4508-.7292.8253-.9744.3778-.2453.8534-.3679 1.4268-.3679.5204 0 .9761.1093 1.3672.3281.3911.2154.6944.5369.9098.9645.2155.4275.3232.9562.3232 1.5859v4.8573h-1.4865v-4.6783c0-.5535-.1442-.986-.4326-1.2976-.2883-.3149-.6844-.4723-1.1882-.4723-.3447 0-.6513.0746-.9197.2237-.2652.1492-.4756.3679-.6314.6563-.1525.285-.2287.6297-.2287 1.0341z" />
                    <path d="m83.244 34.3976v-7.6364h1.4865v7.6364zm.7507-8.8146c-.2586 0-.4806-.0862-.6662-.2586-.1823-.1756-.2735-.3844-.2735-.6264 0-.2452.0912-.4541.2735-.6264.1856-.1757.4076-.2635.6662-.2635.2585 0 .4789.0878.6612.2635.1856.1723.2784.3812.2784.6264 0 .242-.0928.4508-.2784.6264-.1823.1724-.4027.2586-.6612.2586z" />
                    <path d="m81.7102 26.7613v1.1931h-4.3153v-1.1931zm-3.1321 7.6363v-8.5213c0-.4773.1044-.8733.3132-1.1882.2088-.3182.4856-.5552.8303-.7109.3447-.1591.7192-.2387 1.1236-.2387.2983 0 .5535.0249.7656.0746.2121.0464.3695.0895.4723.1293l-.348 1.2031c-.0696-.0199-.1591-.0431-.2685-.0696-.1094-.0298-.2419-.0448-.3977-.0448-.3613 0-.6198.0895-.7756.2685-.1524.179-.2287.4375-.2287.7756v8.3224z" />
                    <path d="m70.8821 31.2307v-4.4695h1.4915v7.6364h-1.4617v-1.3224h-.0795c-.1757.4076-.4574.7473-.8452 1.0191-.3845.2685-.8634.4027-1.4368.4027-.4905 0-.9247-.1077-1.3025-.3231-.3746-.2188-.6695-.5419-.885-.9695-.2121-.4275-.3182-.9562-.3182-1.5859v-4.8573h1.4865v4.6783c0 .5204.1442.9347.4326 1.2429.2883.3082.6629.4624 1.1236.4624.2784 0 .5551-.0696.8302-.2089.2784-.1392.5088-.3496.6911-.6313.1856-.2818.2767-.6397.2734-1.0739z" />
                    <path d="m60.8258 34.5518c-.7159 0-1.3407-.1641-1.8743-.4922s-.9479-.7872-1.2429-1.3771c-.295-.59-.4424-1.2794-.4424-2.0682 0-.7922.1474-1.4849.4424-2.0781.295-.5933.7093-1.054 1.2429-1.3821.5336-.3282 1.1584-.4922 1.8743-.4922s1.3407.164 1.8743.4922c.5336.3281.9479.7888 1.2429 1.3821.295.5932.4425 1.2859.4425 2.0781 0 .7888-.1475 1.4782-.4425 2.0682-.295.5899-.7093 1.049-1.2429 1.3771s-1.1584.4922-1.8743.4922zm.005-1.2479c.464 0 .8485-.1226 1.1534-.3679s.5303-.5717.6761-.9794c.1492-.4077.2238-.8568.2238-1.3473 0-.4872-.0746-.9347-.2238-1.3423-.1458-.411-.3712-.7408-.6761-.9894-.3049-.2485-.6894-.3728-1.1534-.3728-.4673 0-.8551.1243-1.1634.3728-.3049.2486-.5319.5784-.6811.9894-.1458.4076-.2187.8551-.2187 1.3423 0 .4905.0729.9396.2187 1.3473.1492.4077.3762.7341.6811.9794.3083.2453.6961.3679 1.1634.3679z" />
                    <path d="m50.8789 37.2612c-.2221 0-.4242-.0182-.6065-.0547-.1823-.0331-.3182-.0696-.4077-.1093l.358-1.2181c.2717.0729.5137.1044.7258.0945.2121-.01.3994-.0895.5618-.2386.1657-.1492.3115-.3928.4375-.7309l.1839-.5071-2.794-7.7358h1.5909l1.934 5.9262h.0795l1.934-5.9262h1.5958l-3.147 8.6556c-.1458.3977-.3314.7341-.5568 1.0092-.2254.2784-.4938.4872-.8054.6264-.3115.1392-.6728.2088-1.0838.2088z" />
                    <path d="m40.3292 34.5468c-.6165 0-1.1667-.1574-1.6506-.4723-.4806-.3182-.8584-.7706-1.1335-1.3573-.2718-.5899-.4077-1.2975-.4077-2.1228s.1376-1.5313.4127-2.1179c.2784-.5867.6595-1.0358 1.1434-1.3473.4839-.3116 1.0325-.4674 1.6456-.4674.474 0 .8551.0796 1.1435.2387.2917.1558.517.338.6761.5469.1624.2088.2884.3927.3779.5518h.0895v-3.7834h1.4865v10.1818h-1.4517v-1.1882h-.1243c-.0895.1624-.2188.348-.3878.5568-.1657.2089-.3944.3911-.6861.5469s-.6695.2337-1.1335.2337zm.3281-1.2678c.4276 0 .7888-.1127 1.0838-.338.2983-.2287.5237-.5453.6762-.9496.1557-.4044.2336-.875.2336-1.4119 0-.5303-.0762-.9944-.2287-1.3921-.1524-.3977-.3762-.7076-.6711-.9297-.295-.222-.6596-.3331-1.0938-.3331-.4474 0-.8203.116-1.1186.348s-.5237.5486-.6761.9496c-.1492.4011-.2238.8535-.2238 1.3573 0 .5104.0763.9694.2287 1.3771.1525.4077.3779.7308.6762.9694.3016.2354.6728.353 1.1136.353z" />
                    <path d="m33.9842 34.3976v-7.6364h1.4865v7.6364zm.7507-8.8146c-.2585 0-.4806-.0862-.6662-.2586-.1823-.1756-.2734-.3844-.2734-.6264 0-.2452.0911-.4541.2734-.6264.1856-.1757.4077-.2635.6662-.2635s.4789.0878.6612.2635c.1856.1723.2784.3812.2784.6264 0 .242-.0928.4508-.2784.6264-.1823.1724-.4027.2586-.6612.2586z" />
                    <path d="m27.2962 34.3976h-3.2962v-10.1818h3.4006c.9976 0 1.8544.2039 2.5703.6115.7159.4044 1.2644.9861 1.6456 1.7451.3844.7556.5767 1.6621.5767 2.7194 0 1.0606-.1939 1.9721-.5817 2.7344-.3845.7623-.9413 1.3489-1.6704 1.7599-.7292.4077-1.6108.6115-2.6449.6115zm-1.76-1.3423h1.6754c.7756 0 1.4203-.1458 1.934-.4375.5137-.295.8982-.7209 1.1534-1.2777.2552-.5601.3828-1.2429.3828-2.0483 0-.7988-.1276-1.4765-.3828-2.0334-.2519-.5568-.6281-.9794-1.1285-1.2677-.5005-.2884-1.122-.4326-1.8644-.4326h-1.7699z" />
                </g>
                <path d="m112.282 44.5339v8.7273h-1.006v-8.7273z" fill="#838383" />
                <path
                    d="m108.428 50.5849v-3.8693h1.006v6.5454h-1.006v-1.1079h-.068c-.153.3324-.392.615-.716.848-.324.2301-.733.3452-1.227.3452-.409 0-.773-.0895-1.091-.2685-.318-.1818-.568-.4546-.75-.8182-.182-.3665-.273-.8281-.273-1.3849v-4.1591h1.006v4.0909c0 .4773.133.8579.401 1.142.269.2841.613.4262 1.031.4262.25 0 .504-.064.763-.1918.261-.1278.48-.3239.656-.5881.179-.2642.268-.6008.268-1.0099z"
                    fill="#838383"
                />
                <path
                    d="m102.894 46.7157v.8522h-3.5286v-.8522zm-2.472 6.5454v-7.4488c0-.375.088-.6875.264-.9375.177-.25.405-.4375.687-.5625.281-.125.578-.1875.89-.1875.247 0 .449.0198.605.0596.157.0398.273.0767.35.1108l-.29.8693c-.051-.017-.122-.0383-.213-.0639-.088-.0256-.205-.0383-.35-.0383-.332 0-.572.0838-.72.2514-.145.1676-.217.4133-.217.7372v7.2102z"
                    fill="#838383"
                />
                <path
                    d="m92.6665 55.7156v-9h.9716v1.0398h.1194c.0738-.1137.1761-.2586.3068-.4347.1335-.179.3238-.3381.571-.4773.25-.142.5881-.213 1.0142-.213.5511 0 1.0369.1378 1.4574.4133.4204.2756.7486.6662.9844 1.1719s.3537 1.1023.3537 1.7898c0 .6932-.1179 1.294-.3537 1.8025-.2358.5057-.5625.8978-.9801 1.1762-.4177.2755-.8992.4133-1.4447.4133-.4204 0-.7571-.0696-1.0099-.2088-.2528-.142-.4474-.3025-.5838-.4815-.1364-.1818-.2415-.3324-.3153-.4517h-.0853v3.4602zm.9887-5.7273c0 .4943.0724.9304.2173 1.3083.1449.375.3565.669.635.8821.2784.2102.6193.3153 1.0227.3153.4204 0 .7713-.1108 1.0525-.3324.2841-.2244.4972-.5255.6392-.9034.1449-.3807.2174-.804.2174-1.2699 0-.4602-.0711-.875-.2131-1.2443-.1392-.3722-.3508-.6662-.6349-.8821-.2813-.2187-.635-.3281-1.0611-.3281-.4091 0-.7529.1037-1.0313.3111-.2784.2045-.4886.4914-.6306.8608-.1421.3664-.2131.794-.2131 1.2826z"
                    fill="#838383"
                />
                <path d="m90.8246 44.5339v8.7273h-1.0057v-8.7273z" fill="#838383" />
                <path
                    d="m85.5788 53.3974c-.6307 0-1.1747-.1392-1.6321-.4176-.4545-.2812-.8054-.6733-1.0525-1.1761-.2443-.5057-.3665-1.0938-.3665-1.7642 0-.6705.1222-1.2614.3665-1.7728.2471-.5142.5909-.9147 1.0312-1.2017.4432-.2897.9602-.4346 1.5512-.4346.3409 0 .6775.0568 1.0099.1704.3324.1137.6349.2983.9077.554.2727.2528.49.5881.652 1.0057.1619.4176.2429.9318.2429 1.5426v.4261h-5.0455v-.8693h4.0227c0-.3693-.0738-.6988-.2216-.9886-.1448-.2898-.3522-.5185-.6221-.6861-.2671-.1676-.5824-.2514-.946-.2514-.4006 0-.7472.0994-1.0398.2983-.2898.196-.5128.4517-.669.767-.1563.3154-.2344.6534-.2344 1.0142v.5796c0 .4943.0852.9133.2557 1.2571.1733.3409.4133.6008.7201.7798.3068.1761.6634.2642 1.0696.2642.2642 0 .5029-.0369.7159-.1108.216-.0767.402-.1903.5583-.3409.1562-.1534.277-.3437.3622-.571l.9716.2727c-.1023.3296-.2742.6193-.5156.8693-.2415.2472-.5398.4404-.8949.5796-.3551.1363-.7543.2045-1.1975.2045z"
                    fill="#838383"
                />
                <path
                    d="m76.7504 49.3237v3.9375h-1.0057v-8.7273h1.0057v3.2046h.0852c.1534-.3381.3835-.6066.6903-.8054.3097-.2017.7216-.3026 1.2358-.3026.446 0 .8367.0895 1.1719.2685.3352.1761.5952.4474.7798.8139.1875.3636.2813.8267.2813 1.3892v4.1591h-1.0057v-4.0909c0-.5199-.1349-.9219-.4048-1.206-.2671-.2869-.6378-.4304-1.1123-.4304-.3295 0-.625.0696-.8863.2088-.2585.1392-.4631.3424-.6137.6094-.1477.2671-.2215.5909-.2215.9716z"
                    fill="#838383"
                />
                <path
                    d="m70.8569 46.7156v.8523h-3.3921v-.8523zm-2.4034-1.5681h1.0057v6.2386c0 .2841.0412.4972.1235.6392.0853.1392.1932.233.3239.2813.1335.0454.2742.0681.4219.0681.1108 0 .2017-.0056.2727-.017.071-.0142.1279-.0256.1705-.0341l.2045.9034c-.0682.0256-.1633.0511-.2855.0767-.1222.0284-.277.0426-.4645.0426-.2841 0-.5625-.0611-.8352-.1832-.2699-.1222-.4943-.3083-.6733-.5583-.1762-.25-.2642-.5653-.2642-.946z"
                    fill="#838383"
                />
                <path
                    d="m65.1626 53.2612v-6.5454h1.0057v6.5454zm.5114-7.6363c-.196 0-.365-.0668-.5071-.2003-.1392-.1335-.2088-.2941-.2088-.4816s.0696-.348.2088-.4815c.1421-.1335.3111-.2003.5071-.2003s.3636.0668.5028.2003c.1421.1335.2131.294.2131.4815s-.071.3481-.2131.4816c-.1392.1335-.3068.2003-.5028.2003z"
                    fill="#838383"
                />
                <path
                    d="m60.1545 48.1815-.9034.2557c-.0569-.1506-.1407-.2969-.2515-.4389-.1079-.1449-.2556-.2642-.4431-.358-.1875-.0937-.4276-.1406-.7202-.1406-.4006 0-.7344.0923-1.0014.277-.2642.1818-.3963.4133-.3963.6946 0 .25.0909.4474.2727.5923s.4659.2656.8523.3622l.9716.2387c.5852.142 1.0213.3593 1.3082.652.2869.2897.4304.6633.4304 1.1207 0 .375-.108.7102-.3239 1.0057-.213.2954-.5113.5284-.8949.6988-.3835.1705-.8295.2557-1.338.2557-.6676 0-1.2202-.1449-1.6577-.4346-.4375-.2898-.7145-.7131-.831-1.2699l.9546-.2387c.0909.3523.2628.6165.5156.7926.2557.1762.5895.2643 1.0014.2643.4688 0 .8409-.0995 1.1165-.2983.2784-.2017.4176-.4432.4176-.7245 0-.2272-.0795-.4176-.2386-.571-.1591-.1562-.4034-.2727-.733-.3494l-1.0909-.2557c-.5994-.1421-1.0398-.3622-1.321-.6605-.2784-.3012-.4176-.6776-.4176-1.1293 0-.3693.1037-.696.3111-.9801.2102-.2841.4957-.5071.8565-.669.3636-.162.7756-.2429 1.2358-.2429.6477 0 1.1562.142 1.5256.4261.3721.2841.6363.6591.7926 1.125z"
                    fill="#838383"
                />
                <path
                    d="m50.5366 53.3976c-.5455 0-1.027-.1378-1.4446-.4134-.4176-.2784-.7443-.6704-.9801-1.1761-.2358-.5085-.3537-1.1094-.3537-1.8026 0-.6875.1179-1.2841.3537-1.7897.2358-.5057.5639-.8964.9843-1.1719.4205-.2756.9063-.4134 1.4574-.4134.4262 0 .7628.071 1.01.2131.25.1392.4403.2983.571.4773.1335.1761.2372.321.3111.4346h.0852v-3.2216h1.0057v8.7273h-.9716v-1.0057h-.1193c-.0739.1193-.179.2699-.3154.4517-.1363.179-.3309.3395-.5838.4816-.2528.1392-.5895.2088-1.0099.2088zm.1363-.9034c.4034 0 .7444-.1051 1.0228-.3154.2784-.213.49-.5071.6349-.8821.1449-.3778.2173-.8139.2173-1.3082 0-.4887-.071-.9162-.213-1.2827-.1421-.3693-.3523-.6562-.6307-.8608-.2784-.2074-.6222-.3111-1.0313-.3111-.4261 0-.7812.1094-1.0653.3282-.2813.2159-.4929.5099-.6349.8821-.1393.3693-.2089.7841-.2089 1.2443 0 .4659.0711.8892.2131 1.2699.1449.3778.358.6789.6392.9034.2841.2216.6364.3324 1.0568.3324z"
                    fill="#838383"
                />
                <path
                    d="m42.0511 49.3236v3.9375h-1.0056v-6.5455h.9715v1.0227h.0853c.1534-.3324.3863-.5994.6988-.8011.3125-.2046.7159-.3068 1.2103-.3068.4431 0 .8309.0909 1.1633.2727.3324.179.5909.4517.7756.8182.1846.3636.277.8238.277 1.3807v4.1591h-1.0057v-4.091c0-.5142-.1335-.9147-.4006-1.2017-.267-.2897-.6335-.4346-1.0994-.4346-.321 0-.608.0696-.8608.2088-.25.1392-.4474.3423-.5923.6094-.1449.267-.2174.5909-.2174.9716z"
                    fill="#838383"
                />
                <path
                    d="m38.1978 53.2612v-6.5454h1.0057v6.5454zm.5114-7.6363c-.1961 0-.3651-.0668-.5071-.2003-.1392-.1335-.2088-.2941-.2088-.4816s.0696-.348.2088-.4815c.142-.1335.311-.2003.5071-.2003.196 0 .3636.0668.5028.2003.1421.1335.2131.294.2131.4815s-.071.3481-.2131.4816c-.1392.1335-.3068.2003-.5028.2003z"
                    fill="#838383"
                />
                <path
                    d="m36.7884 46.7157v.8522h-3.5285v-.8522zm-2.4716 6.5454v-7.4488c0-.375.088-.6875.2642-.9375.1761-.25.4048-.4375.6861-.5625.2812-.125.5781-.1875.8906-.1875.2471 0 .4488.0198.6051.0596.1562.0398.2727.0767.3494.1108l-.2898.8693c-.0511-.017-.1221-.0383-.213-.0639-.0881-.0256-.2046-.0383-.3495-.0383-.3323 0-.5724.0838-.7201.2514-.1449.1676-.2174.4133-.2174.7372v7.2102z"
                    fill="#838383"
                />
                <path
                    d="m27.9716 44.5339v8.7273h-1.0568v-7.6193h-.0512l-2.1306 1.4148v-1.0739l2.1818-1.4489z"
                    fill="#838383"
                />
                <rect height="38" rx="19" stroke="#ececec" strokeWidth="2" width="38" x="285" y="21" />
                <path
                    d="m294.833 48.3334h3.334v-10h-3.334zm18.334-9.1667c0-.9166-.75-1.6666-1.667-1.6666h-5.258l.791-3.8084.025-.2666c0-.3417-.141-.6584-.366-.8834l-.884-.875-5.483 5.4917c-.308.3-.492.7167-.492 1.175v8.3333c0 .9167.75 1.6667 1.667 1.6667h7.5c.692 0 1.283-.4167 1.533-1.0167l2.517-5.875c.075-.1916.117-.3916.117-.6083z"
                    fill="#ececec"
                />
                <rect height="38" rx="19" stroke="#ececec" strokeWidth="2" width="38" x="333" y="21" />
                <path
                    d="m354.5 31.6667h-7.5c-.692 0-1.283.4167-1.533 1.0167l-2.517 5.875c-.075.1917-.117.3917-.117.6083v1.6667c0 .9167.75 1.6667 1.667 1.6667h5.258l-.791 3.8083-.025.2667c0 .3416.141.6583.366.8833l.884.875 5.491-5.4917c.3-.3.484-.7166.484-1.175v-8.3333c0-.9167-.75-1.6667-1.667-1.6667zm3.333 0v10h3.334v-10z"
                    fill="#ececec"
                />
            </g>
        </svg>
    );
}
