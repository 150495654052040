export const PRICES: { [k: string]: { monthly: number; yearly: number; lifetime?: number } } = {
    '5000': {
        monthly: 19,
        yearly: 190,
        lifetime: 380,
    },
    '10000': {
        monthly: 23,
        yearly: 230,
        lifetime: 460,
    },
    '15000': {
        monthly: 27,
        yearly: 270,
        lifetime: 540,
    },
    '20000': {
        monthly: 31,
        yearly: 310,
        lifetime: 620,
    },
    '25000': {
        monthly: 35,
        yearly: 350,
        lifetime: 700,
    },
    '30000': {
        monthly: 39,
        yearly: 390,
    },
    '35000': {
        monthly: 43,
        yearly: 430,
    },
    '40000': {
        monthly: 47,
        yearly: 470,
    },
    '45000': {
        monthly: 51,
        yearly: 510,
    },
    '50000': {
        monthly: 55,
        yearly: 550,
    },
    '55000': {
        monthly: 59,
        yearly: 590,
    },
    '60000': {
        monthly: 63,
        yearly: 630,
    },
    '65000': {
        monthly: 67,
        yearly: 670,
    },
    '70000': {
        monthly: 71,
        yearly: 710,
    },
    '75000': {
        monthly: 75,
        yearly: 750,
    },
    '80000': {
        monthly: 79,
        yearly: 790,
    },
    '85000': {
        monthly: 83,
        yearly: 830,
    },
    '90000': {
        monthly: 87,
        yearly: 870,
    },
    '95000': {
        monthly: 91,
        yearly: 910,
    },
    '100000': {
        monthly: 95,
        yearly: 950,
    },
    '105000': {
        monthly: 95,
        yearly: 950,
    },
};
