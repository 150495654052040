import React from 'react';
import { hydrate, render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import './index.scss';
import { registerCallableComponent } from './service/common/registerCallableComponents';
import Faq from './sections/faq';
import Header from './sections/header';
import Footer from './sections/footer';
import JoinBetaProgram from './sections/modals/join-beta-program';
import { registerComponent } from './service/common/registerComponents';
import { BackgroundTop } from './sections/backgroundTop';
import Hero from './sections/hero';
import VideoDemo from './sections/VideoDemo';
import ToolTargets from './sections/ToolTargets';
import HowItWorks from './sections/howItWorks';
import ForYourReaders from './sections/for-your-readers';
import UseCases from './sections/use-cases';
import Pricing from './sections/pricing';
import ForYou from './sections/for-you';
import PowerfulIsland from './sections/powerful-island';
import Privacy from './sections/privacy';
import FreeTrial from './sections/free-trial';
import { updateBlogComponents } from './service/common/registerBlogComponent';
import { Article } from './blogUniqrate/article';
import { ArticlesList } from './blogUniqrate/articlesList';
import { Author } from './blogUniqrate/author';
import { Category } from './blogUniqrate/category';
import WhyBloggersLoveUniqrate from './sections/why-bloggers-love-uniqrate';
import HeroBloggers from './sections/hero-bloggers';
import StartInLessThanMinute from './sections/start-in-less-than-minute';
import CompatibleWith from './sections/compatible-with';
import TurnDataIntoGrowth from './sections/turn-data-into-growth';
import FreeTrialBloggers from './sections/free-trial-bloggers';
import HeaderSignUp from './sections/header-signup';
import HeroTechnical from './sections/hero-technical';
import GetInsights from './sections/get-insights';
import SeamlessIntegration from './sections/seamless-integration';

// Polyfill Object.hasOwn for react-markdown with react-snap
// From : https://github.com/remarkjs/react-markdown/issues/772
if (!Object.hasOwn) {
    Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
}

registerComponent('hero', Hero);
registerComponent('video-demo', VideoDemo);
registerComponent('background-top', BackgroundTop);
registerComponent('tool-targets', ToolTargets);
registerComponent('how-it-works', HowItWorks);
registerComponent('for-your-readers', ForYourReaders);
registerComponent('for-you', ForYou);
registerComponent('header', Header);
registerComponent('header-signup', HeaderSignUp);
//registerComponent('what-people-think', WhatPeopleThink);
registerComponent('use-cases', UseCases);
registerComponent('powerful-island', PowerfulIsland);
registerComponent('privacy', Privacy);
registerComponent('pricing', Pricing);
registerComponent('for-faq', Faq);
registerComponent('free-trial', FreeTrial);
registerComponent('footer', Footer);
registerComponent('why-bloggers-love-uniqrate', WhyBloggersLoveUniqrate);
registerComponent('hero-bloggers', HeroBloggers);
registerComponent('hero-technical', HeroTechnical);
registerComponent('start-in-less-than-minute', StartInLessThanMinute);
registerComponent('compatible-with', CompatibleWith);
registerComponent('turn-data-into-growth', TurnDataIntoGrowth);
registerComponent('free-trial-bloggers', FreeTrialBloggers);
registerComponent('get-insights', GetInsights);
registerComponent('seamless-integration', SeamlessIntegration);

registerCallableComponent('joinBetaProgram', JoinBetaProgram);

// registerBlogComponents(Article, ArticlesList, Author, Category); // Default is already setted
// registerDocumentationComponents(Documentation, DocumentationMenu); // Default is already setted
updateBlogComponents(Article, ArticlesList, Author, Category);

const rootElement = document.getElementById('root') as HTMLElement;

if (rootElement.hasChildNodes()) {
    hydrate((<RouterProvider router={router} />) as any, rootElement);
} else {
    render((<RouterProvider router={router} />) as any, rootElement);
}
