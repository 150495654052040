export function Spoke({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m16 7c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4zm-9 6c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm10 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
                fill="#fff"
            />
        </svg>
    );
}

export function Power({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m16.6767 7-.01-4h-2v4h-4v-4h-2.00004v4h-.01c-.99-.01-1.99.99-1.99 1.99v5.49l3.50004 3.52v3h5v-3l3.5-3.51v-5.5c0-1-1-2-1.99-1.99z"
                fill="#fff"
            />
        </svg>
    );
}

export function ColorLens({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m12.3334 3c-4.97003 0-9.00003 4.03-9.00003 9s4.03 9 9.00003 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5h1.77c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.50003 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3-4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5c.83003 0 1.50003.67 1.50003 1.5s-.67 1.5-1.50003 1.5zm5.00003 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                fill="#fff"
            />
        </svg>
    );
}

export function Start({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m14.59 7.41 3.58 3.59h-12.17v2h12.17l-3.59 3.59 1.42 1.41 6-6-6-6zm-12.59-1.41v12h2v-12z"
                fill="#fff"
            />
        </svg>
    );
}

export function Bolt({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m11.663 21h-1l1-7h-3.50003c-.58 0-.57-.32-.38-.66s.05-.08.07-.12c1.29-2.28 3.23003-5.68 5.81003-10.22h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15c-3.94 6.89-5.9 10.34-5.9 10.34z"
                fill="#fff"
            />
        </svg>
    );
}

export function Settings({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m19.4734 12.9399c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43002.17-.47002.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22l-1.91 3.32c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24002.41.48002.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zm-7.14 2.66c-1.98 0-3.60002-1.62-3.60002-3.6s1.62002-3.6 3.60002-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"
                fill="#fff"
            />
        </svg>
    );
}
