import { Cookie, Lock, Shield } from '../svgComponents/privacy';

export default function Privacy() {
    return (
        <div className={'rmc-bg-primary-4 px-40 max-lg:px-8 pb-[56px]'}>
            <div className={'max-w-[1240px] m-auto'}>
                <div
                    className={
                        'm-auto w-fit flex rmc-bg-green-3 p-[6px] pr-4 gap-2 justify-center items-center rounded-[30px] mb-4'
                    }
                >
                    <img loading="lazy" src={'/eu-flag.svg'} alt={'European union flag'} />
                    <span className={'text-[13px] font-bold leading-[20.8px]'}>GDPR-compliant</span>
                </div>
                <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center  m-auto mb-6 leading-none'}>
                    Privacy first
                </h1>
                <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center max-w-3xl mb-10'}>
                    Natively GDPR-compliant analytics and feedback.
                    <br />
                    Understand your readers better than ever.
                    <br />
                    Respect their privacy, more than before.
                </p>
                <div className={'flex gap-6 max-lg:flex-col'}>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-1/3 max-lg:w-full'
                        }
                    >
                        <div className={'h-20 min-w-[5rem] rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Lock className={'m-auto'} />
                        </div>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-semibold text-xl leading-5'}>Data location</p>
                            <p className={'font-normal text-base leading-[25.6px]'}>
                                Hosted in US or EU for data sovereignty compliance
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-1/3 max-lg:w-full'
                        }
                    >
                        <div className={'h-20 min-w-[5rem] rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Cookie className={'m-auto'} />
                        </div>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-semibold text-xl leading-5'}>No cookie banner</p>
                            <p className={'font-normal text-base leading-[25.6px]'}>
                                No cookie banner required. Frictionless reader journey
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-1/3 max-lg:w-full'
                        }
                    >
                        <div className={'h-20 min-w-[5rem] rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Shield className={'m-auto'} />
                        </div>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-semibold text-xl leading-5'}>Fully anonymous</p>
                            <p className={'font-normal text-base leading-[25.6px]'}>
                                All the site measurement is carried out anonymously
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
