import { IconInterface } from './circle-arrow-left';

export default function CircleArrowRight({ fill }: IconInterface) {
    return (
        <svg
            className={'self-center'}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="M22.6667 12C22.6667 6.48 18.1867 2 12.6667 2C7.14666 2 2.66666 6.48 2.66666 12C2.66666 17.52 7.14666 22 12.6667 22C18.1867 22 22.6667 17.52 22.6667 12ZM12.6667 13H8.66666V11H12.6667V8L16.6667 12L12.6667 16V13Z"
            />
        </svg>
    );
}
