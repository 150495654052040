export function Lock({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m18 8.5h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1z"
                fill="#fff"
            />
        </svg>
    );
}

export function Cookie({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m22.2831 11.0038c-1.79-.03-3.7-1.95-2.68-4.22-2.98 1-5.77-1.59-5.19-4.56-7.13003-1.5-12.08003 4.37-12.08003 9.79 0 5.52 4.48 10 10.00003 10 5.89 0 10.54-5.08 9.95-11.01zm-13.45003 4.01c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.50003.67 1.50003 1.5-.67003 1.5-1.50003 1.5zm2.00003-5c-.83 0-1.50003-.67-1.50003-1.5s.67003-1.5 1.50003-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                fill="#fff"
            />
        </svg>
    );
}

export function Shield({ className }: { className: string }) {
    return (
        <svg
            className={className}
            fill="none"
            height="24"
            viewBox="0 0 25 24"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m12.6666 1-8.99997 4v6c0 5.55 3.84 10.74 8.99997 12 5.16-1.26 9-6.45 9-12v-6z" fill="#fff" />
        </svg>
    );
}
