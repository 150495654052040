import { CategoryModel, SettingModel } from '../service/common/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/common/seoService';
import React from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildAuthorUrlFromPath } from '../urlBuilder';
import { getBlogComponents } from '../service/common/registerBlogComponent';

export function CategoryRenderer({ category, setting }: { category: CategoryModel; setting: SettingModel }) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl = rootUrl + buildAuthorUrlFromPath(category.locale as string, category.path as string, setting);
    const Category = getBlogComponents().categoryComponent;

    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: category.locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: getFirstWords(category.description || '', 20),
                    title: category.name,
                    image: seoService.defaultImage(rootUrl),
                })}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: getFirstWords(category.description || '', 20),
                        title: category.name,
                        image: seoService.defaultImage(rootUrl),
                    },
                    {
                        siteAccount: seoService.defaultTwitterSiteAccount(),
                        creatorAccount: seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>
            <div>
                <SectionsRenderer sections={setting.blogSectionsBefore} locale={category.locale as string} />
                <Category url={pageUrl} category={category} />
                <SectionsRenderer sections={setting.blogSectionsAfter} locale={category.locale as string} />
            </div>
        </div>
    );
}

// Function that get 200 first words of a string
function getFirstWords(str: string, n: number) {
    return str.split(' ').slice(0, n).join(' ');
}
