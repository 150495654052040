import React from 'react';

export default function WhyBloggersLoveUniqrate() {
    return (
      <div className='rmc-bg-primary-4 pt-[60px]'>
        <div className="mx-auto max-w-[1240px] px-6 lg:px-0 py-[60px] flex flex-col gap-12">
          <div className="flex flex-col gap-6">
            <h2 className="text-center text-[#2a2728] text-[61px] font-extrabold leading-[61px]">Why Bloggers love Uniqrate</h2>
            <p className="text-center text-[#2a2728] text-xl font-normal leading-loose">Easy and seamless integration on your content.</p>
          </div>
          <div className="flex flex-col gap-6">
            {/* Instant */}
            <div className="grid grid-cols-1 sm:grid-cols-6 lg:grid-cols-4 gap-6">
              <div className="flex-col col-span-4 sm:col-span-4 lg:col-span-2 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#2a2728] bg-gradient-to-b from-black to-transparent flex-col gap-2">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">Instant reader feedback</div>
                  <p className="text-white text-base font-normal leading-relaxed">
                    Improve your blog by listening to what readers value and what they skip over. Capture real-time feedback from your readers and use it to refine each post for maximum impact.
                  </p>
                </div>
                <div className="h-full bg-contain bg-[url('../public/img-why-love-01@2x.png')]"></div>
              </div>

              {/* Social */}
              <div className="flex-col col-span-4 sm:col-span-2 lg:col-span-1 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#2a2728] bg-gradient-to-b from-black to-transparent flex-col gap-2">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">Social proof</div>
                  <p className="text-white font-normal leading-relaxed">
                    Build trust around your content by displaying the number of people loving it.
                  </p>
                </div>
                <div className="h-full bg-cover bg-[url('../public/img-why-love-05@2x.png')]"></div>
              </div>

              {/* Easy */}
              <div className="flex-col col-span-4 sm:col-span-3 lg:col-span-1 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#fcedd8] bg-gradient-to-b from-[#fdeed8] to-transparent flex-col gap-2">
                  <div className="text-[#2a2728 text-[25px] font-semibold leading-[25px]">Easy integration</div>
                  <p className="text-[#2a2728 font-normal leading-relaxed">
                    Lightweight widget simple to install - no technical skills required. Just a few lines of code, and you’re ready to start. Compatible with popular platforms like WordPress, Webflow and Ghost.
                  </p>
                </div>
                <div className="h-full bg-cover bg-[url('../public/img-why-love-02.png')]"></div>
              </div>

              {/* Privacy */}
              <div className="flex-col col-span-4 sm:col-span-3 lg:col-span-1 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#fcedd8] bg-gradient-to-b from-[#fdeed8] to-transparent flex-col gap-2">
                  <div className="text-[#2a2728] text-[25px] font-semibold font-['Inter'] leading-[25px]">Privacy first</div>
                  <p className="self-stretch text-[#2a2728] text-base font-normal font-['Inter'] leading-relaxed">
                    Your readers’ privacy is our priority. Uniqrate is 100% cookie-free and fully GDPR-compliant.
                  </p>
                </div>
                <div className="h-full pt-4 bg-cover bg-[url('../public/img-why-love-04@2x.png')]">
                  <span className={'flex w-fit gap-2 m-auto mt-2 items-center'}>
                      <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={''} />{' '}
                      Privacy friendly
                  </span>
                  <span className={'flex w-fit gap-2 m-auto mt-2 items-center'}>
                      <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={''} />{' '}
                      Cookie free
                  </span>
                  <div className={'m-auto w-fit mt-4 flex rmc-bg-green-3 p-[6px] pr-4 gap-2 justify-center items-center rounded-[30px]'}>
                      <img loading="lazy" src={'/eu-flag.svg'} alt={'European union flag'} />
                      <span className={'text-[13px] font-bold leading-[20.8px]'}>GDPR-compliant</span>
                  </div>
                </div>
              </div>

              {/* Optimize */}
              <div className="flex-col col-span-4 sm:col-span-2 lg:col-span-1 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex grow p-8 bg-[#2a2728] bg-gradient-to-b from-black to-transparent flex-col gap-2">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">Optimize content strategy</div>
                  <p className="text-white text-base font-normal leading-relaxed">
                    Getinsights to align your content strategy with readers' interests. Discover top-performing topic, styles to boost engagement, reduce bounce rates, and enhance your blog's SEO.
                  </p>
                </div>
                <div className="h-full bg-cover bg-[url('../public/img-why-love-03.png')]"></div>
              </div>

              <div className="flex-col col-span-4 lg:col-span-2 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#2a2728] bg-gradient-to-b from-black to-transparent flex-col gap-2">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">Boost engagement</div>
                  <p className="text-white text-base font-normal leading-relaxed">
                    Use real-time reader feedback to refine your content and focus on what truly resonates. Higher engagement rates, longer session times, and returning visitors build a loyal audience and also positively impact your SEO.
                  </p>
                </div>
                <div className="h-full bg-cover bg-[url('../public/img-why-love-05@2x.png')]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}