export default function HowItWorks() {
    return (
        <div className={'bg-white pt-[120px] mb-12 px-40 max-lg:px-8'}>
            <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center  m-auto mb-6 leading-none'}>
                How does it work?
            </h1>
            <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center max-w-3xl'}>
                A plug an play widget, at the bottom center of your blog or documentation. That let your readers rate,
                comment and engage with your content.
            </p>
        </div>
    );
}
