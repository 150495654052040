import React from 'react';

export function BackgroundTop({ position = 'absolute' }: { position?: 'fixed' | 'absolute' }) {
    return (
        <div
            className={`${position} inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80`}
            aria-hidden="true"
        >
            <img loading="lazy" src="/background.svg" style={{ height: '1000px', opacity: '20%' }} alt="" />
        </div>
    );
}
