import { ArticleModel, SettingModel } from '../service/common/database';
import { buildArticleUrlFromPath, buildAuthorUrlFromPath, buildCategoryUrlFromPath } from '../urlBuilder';
import { Link } from 'react-router-dom';

export function ArticlesList({
    articles,
    locale,
    setting,
}: {
    articles: ArticleModel[];
    locale: string;
    setting: SettingModel;
}) {
    return (
        <div className="pt-14 pb-20">
            <div className="mx-auto max-w-5xl px-8">
                <div className="mx-0 sm:mx-auto max-w-2xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{setting.blogName}</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">{setting.blogDescription}</p>
                </div>
                <ul className="grid grid-cols-1 gap-x-8 gap-y-16 pt-14 mx-0 max-w-none sm:grid-cols-2 xl:grid-cols-3">
                    {articles
                        .filter((a) => a.locale === locale)
                        .map((article) => (
                            <li
                                key={article.path}
                                className="flex overflow-hidden flex-col items-start justify-between bg-white shadow rounded-lg"
                            >
                                <div className="relative w-full">
                                    <Link
                                        to={buildArticleUrlFromPath(
                                            article.locale as string,
                                            article.path as string,
                                            setting,
                                        )}
                                    >
                                        <img
                                            loading="lazy"
                                            src={article.image}
                                            alt={article.title + ' image.'}
                                            className="aspect-[16/9] w-full bg-gray-100 object-cover lg:aspect-[3/2]"
                                        />
                                    </Link>
                                </div>
                                <div className="px-2 py-4">
                                    <div className="group relative">
                                        <h3 className="text-xl font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <Link
                                                to={buildArticleUrlFromPath(
                                                    article.locale as string,
                                                    article.path as string,
                                                    setting,
                                                )}
                                            >
                                                {article.title}
                                            </Link>
                                        </h3>
                                        <p className="mt-3 line-clamp-3 text-sm leading-6 text-gray-600">
                                            {article.description}
                                        </p>
                                    </div>
                                    <section className="relative mt-5 flex items-center">
                                        <div className="flex-1 flex gap-x-2 items-center">
                                            <img
                                                loading="lazy"
                                                src={article.author.avatar || '/default-avatar.png'}
                                                alt="user avatar"
                                                className="h-10 w-10 rounded-full bg-gray-100"
                                            />

                                            <div className="text-sm leading-5 flex-1">
                                                <p className="font-semibold text-gray-900">
                                                    <Link
                                                        to={buildAuthorUrlFromPath(
                                                            article.author.locale as string,
                                                            article.author.path as string,
                                                            setting,
                                                        )}
                                                    >
                                                        <span className="absolute inset-0" />
                                                        {article.author.firstname} {article.author.lastname}
                                                    </Link>
                                                </p>
                                                <p className="text-gray-600">{article.author.role}</p>
                                            </div>
                                        </div>
                                        <Link
                                            to={buildCategoryUrlFromPath(
                                                article.category.locale as string,
                                                article.category.path as string,
                                                setting,
                                            )}
                                            className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-sm text-gray-600 hover:bg-gray-100"
                                        >
                                            {article.category.name}
                                        </Link>
                                    </section>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}
