import React, { useState } from 'react';
import Bullet from '../icons/bullet';

export default function ForYourReaders() {
    const [activeItem, setActiveItem] = useState('trust');

    const getImage = () => {
        switch (activeItem) {
            case 'trust':
                return (
                    <img
                        loading="lazy"
                        alt={'island black and white, social proof displayed'}
                        src={'/img-for_your_reader-01.webp'}
                        srcSet={'img-for_your_reader-01@2x.webp 2x'}
                        className={'h-auto w-full'}
                    />
                );
            case 'ratings':
                return (
                    <img
                        loading="lazy"
                        src={'/img-for_your_reader-02.webp'}
                        srcSet={'img-for_your_reader-02@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                );
            case 'comments':
                return (
                    <img
                        loading="lazy"
                        src={'/img-for_your_reader-03.webp'}
                        srcSet={'img-for_your_reader-03@2x.webp 2x'}
                        alt={'Island with comments box'}
                        className={'h-auto w-full'}
                    />
                );
            case 'reward':
                return (
                    <img
                        loading="lazy"
                        src={'/img-for_your_reader-04.webp'}
                        srcSet={'img-for_your_reader-04@2x.webp 2x'}
                        alt={'Island with engage input'}
                        className={'h-auto w-full'}
                    />
                );
            case 'thank':
                return (
                    <img
                        loading="lazy"
                        src={'/img-for_your_reader-05.webp'}
                        srcSet={'img-for_your_reader-05@2x.webp 2x'}
                        alt={'Island with thank you feedback'}
                        className={'h-auto w-full'}
                    />
                );
        }
    };
    return (
        <div className={'bg-white mb-20 mx-40 max-lg:mx-0'}>
            <div
                className={
                    'flex rmc-bg-secondary-2 p-8 rounded-3xl max-w-[1240px] max-xl:flex-col m-auto max-lg:rounded-none'
                }
            >
                <div className={'flex flex-col pr-8 max-lg:pr-0 gap-4'}>
                    <p className="py-[3px] px-2.5 rmc-bg-primary-5 rounded-[30px] w-fit font-bold text-[13px] mb-4 max-xl:m-auto">
                        For your readers
                    </p>
                    <h1
                        className={
                            'font-semibold text-[39px] text-left mb-16 max-xl:text-center max-xl:mb-10 leading-[39px] max-lg:mb-4'
                        }
                    >
                        Readers give Feedback without distraction
                    </h1>
                    <div className={'flex flex-col pr-8 text-[25px] leading-[25px] max-lg:pr-0'}>
                        <p
                            className={`flex gap-6 border-t rmc-border-base-2 py-3 font-semibold ${activeItem === 'trust' ? 'rmc-text-base-1' : 'rmc-text-base-2 pl-8'} items-center`}
                            onMouseEnter={() => setActiveItem('trust')}
                            onClick={() => setActiveItem('trust')}
                        >
                            {activeItem === 'trust' && <Bullet />}
                            Create trust around your content
                        </p>
                        <p
                            onMouseEnter={() => setActiveItem('ratings')}
                            onClick={() => setActiveItem('ratings')}
                            className={`flex gap-6 border-t rmc-border-base-2 py-3 font-semibold items-center ${activeItem === 'ratings' ? 'rmc-text-base-1' : 'rmc-text-base-2 pl-8'}`}
                        >
                            {activeItem === 'ratings' && <Bullet />}
                            Get ratings
                        </p>
                        <p
                            onMouseEnter={() => setActiveItem('comments')}
                            onClick={() => setActiveItem('comments')}
                            className={`flex gap-6 border-t rmc-border-base-2 py-3 font-semibold items-center ${activeItem === 'comments' ? 'rmc-text-base-1' : 'rmc-text-base-2 pl-8'}`}
                        >
                            {activeItem === 'comments' && <Bullet />}
                            Get comments
                        </p>
                        <p
                            onMouseEnter={() => setActiveItem('reward')}
                            onClick={() => setActiveItem('reward')}
                            className={`flex gap-6 border-t rmc-border-base-2 py-3 items-center font-semibold ${activeItem === 'reward' ? 'rmc-text-base-1' : 'rmc-text-base-2 pl-8'}`}
                        >
                            {activeItem === 'reward' && <Bullet />}
                            Reward them. Get their emails
                        </p>
                        <p
                            onMouseEnter={() => setActiveItem('thank')}
                            onClick={() => setActiveItem('thank')}
                            className={`flex gap-6 border-t rmc-border-base-2 py-3 items-center font-semibold ${activeItem === 'thank' ? 'rmc-text-base-1' : 'rmc-text-base-2 pl-8'}`}
                        >
                            {activeItem === 'thank' && <Bullet />}
                            Thanks them
                        </p>
                    </div>
                </div>
                <div>{getImage()}</div>
            </div>
        </div>
    );
}
