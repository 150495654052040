import React from 'react';
import { Link } from 'react-router-dom';

export default function UseCases() {
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;

    return (
        <div className="rmc-bg-primary-4 px-40 flex flex-col max-[1300px]:px-0 pt-[120px]">
            <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center m-auto mb-10 leading-none'}>
                Use cases
            </h1>
            <div className={'flex gap-10 max-[1300px]:flex-col'}>
                <div
                    className={
                        'flex flex-col w-1/3 rmc-bg-secondary-2 p-6 rounded-3xl max-[1300px]:rounded-none lg:min-h-[570px] max-[1370px]:h-auto h-fit max-[1300px]:w-full'
                    }
                >
                    <p className={'mb-2 text-xl rmc-text-base-1 leading-8 font-semibold'}>For Bloggers</p>
                    <img
                        loading="lazy"
                        className={'h-[149px] rmc-bg-secondary-2 rounded-3xl mb-6 object-cover'}
                        src={'/img-use_case-01.webp'}
                        srcSet={'/img-use_case-01@2x.webp 2x'}
                        alt={''}
                    />
                    <div className={'flex flex-col gap-4 rmc-text-base-1 max-lg:mb-4'}>
                        <p className={'flex gap-2 '}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            <p className={'flex flex-col'}>
                                <span className={'font-bold'}>Power of Social Proof</span>
                                Increase bounce rate
                                <br />
                                Increase average time spent
                            </p>
                        </p>
                        <p className={'flex gap-2 font-bold'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            Identify the content your readers love and want to read more
                        </p>
                        <p className={'flex gap-2'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            <p>
                                <span className={'font-bold'}>Identify the unasked question</span> of your readers.
                            </p>
                        </p>
                    </div>

                    <Link
                        to={signupUrl}
                        className={
                            'font-medium text-center rmc-bg-primary-1 text-white rounded-lg text-lg hover:rmc-bg-primary-3 py-4 px-8 mt-auto'
                        }
                    >
                        ✍️ Get it on your blog
                    </Link>
                </div>
                <div
                    className={
                        'flex flex-col w-1/3 rmc-bg-secondary-2 p-6 rounded-3xl max-[1300px]:rounded-none lg:min-h-[570px] max-[1300px]:w-full max-[1370px]:h-auto h-fit'
                    }
                >
                    <p className={'mb-2 text-xl rmc-text-base-1 leading-8 font-semibold'}>For Technical Writers</p>
                    <img
                        loading="lazy"
                        alt={''}
                        className={'h-[149px] rmc-bg-secondary-2 rounded-3xl mb-6 object-cover'}
                        src={'/img-use_case-02.webp'}
                        srcSet={'/img-use_case-02@2x.webp 2x'}
                    />
                    <div className={'flex flex-col gap-4 rmc-text-base-1 max-lg:mb-4'}>
                        <p className={'flex gap-2 font-bold'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            Insure accuracy of your documentation
                        </p>
                        <p className={'flex gap-2'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            <p>
                                Get the <span className={'font-bold'}>content that help</span> your readers
                            </p>
                        </p>
                        <p className={'flex gap-2 font-bold'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            Identify the questions your readers are asking
                        </p>
                    </div>
                    <Link
                        to={signupUrl}
                        className={
                            'font-medium text-center rmc-bg-primary-1 text-white rounded-lg text-lg hover:rmc-bg-primary-3 py-4 px-8 mt-auto'
                        }
                    >
                        🗄️ Get for documentation
                    </Link>
                </div>
                <div
                    className={
                        'flex flex-col w-1/3 rmc-bg-secondary-2 p-6 rounded-3xl max-[1300px]:rounded-none lg:min-h-[570px] max-[1300px]:w-full max-[1370px]:h-auto h-fit'
                    }
                >
                    <p className={'mb-2 text-xl rmc-text-base-1 leading-8 font-semibold'}>For AI-Generated Content</p>
                    <img
                        loading="lazy"
                        alt={''}
                        className={'h-[149px] rmc-bg-secondary-2 rounded-3xl mb-6 object-cover'}
                        src={'/img-use_case-03.webp'}
                        srcSet={'/img-use_case-03@2x.webp 2x'}
                    />
                    <div className={'flex flex-col gap-4 rmc-text-base-1 max-lg:mb-4'}>
                        <p className={'flex gap-2 font-bold'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            Identify the content the readers don’t like before Search engines
                        </p>
                        <p className={'flex gap-2'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            <p>
                                <span className={'font-bold'}>Understand your content issues</span> with readers
                                comments
                            </p>
                        </p>
                        <p className={'flex gap-2'}>
                            <img loading="lazy" alt={''} src={'/check_circle_green.svg'} className={'self-start'} />
                            <p>
                                <span className={'font-bold'}>Build trust</span> on your best and most visited content
                                with social proof
                            </p>
                        </p>
                    </div>
                    <Link
                        to={signupUrl}
                        className={
                            'font-medium text-center rmc-bg-primary-1 text-white rounded-lg text-lg hover:rmc-bg-primary-3 py-4 px-8 mt-auto'
                        }
                    >
                        🧑‍💻 Get it for your content
                    </Link>
                </div>
            </div>
        </div>
    );
}
