import React from 'react';
import Highlighter from './components/highlighter';

export default function GetInsights() {
    return (
        <div className='rmc-bg-primary-4 pt-[60px]'>
            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='col-span-2 md:col-span-1 order-last md:order-first'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-02.webp'}
                        srcSet={'../img-for_your_reader-02@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                </div>
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1 order-first md:order-last'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Get Insights to Perfect Your Documentation</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Uniqrate lets you identify sections that confuse readers, areas where understanding drops, and parts that receive the most engagement. Instead of relying on guesswork, use actual reader data to refine your documentation and close knowledge gaps.
                    </p>
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Engage Your Readers and Make Data-Driven Improvements</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Every piece of content you write impacts your users’ experience. Uniqrate gives you a straightforward way to gather ratings and comments, ensuring your documentation aligns with reader expectations. Spot inaccuracies, respond to confusion, and create a feedback loop that turns your readers into collaborators.
                    </p>
                </div>
                <div className='col-span-2 md:col-span-1'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-03.webp'}
                        srcSet={'../img-for_your_reader-03@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='col-span-2 md:col-span-1 order-last md:order-first'>
                    <div className='col-span-2 md:col-span-1'>
                        <img
                            loading="lazy"
                            src={'../img-for_your_reader-01.webp'}
                            srcSet={'../img-for_your_reader-01@2x.webp 2x'}
                            alt={'Ratings on island black theme'}
                            className={'h-auto w-full'}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1 order-first md:order-last justify-start'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Boost Trust Through Consistency and Precision</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Accurate, user-focused documentation builds trust. Uniqrate’s feedback tools are designed to help you become an expert in what your readers need, allowing you to continuously improve and ensure that your content always remains relevant and precise.
                    </p>           
                </div>
            </div>
        </div>
    );
}