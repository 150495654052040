export default function CheckCircle({ className = '', fill = '#7A7174' }: { className?: string; fill?: string }) {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                width: '24px',
                height: '24px',
            }}
        >
            <g id="check_circle">
                <path
                    id="Vector"
                    d="M12 2.00049C6.48 2.00049 2 6.48049 2 12.0005C2 17.5205 6.48 22.0005 12 22.0005C17.52 22.0005 22 17.5205 22 12.0005C22 6.48049 17.52 2.00049 12 2.00049ZM12 20.0005C7.59 20.0005 4 16.4105 4 12.0005C4 7.59049 7.59 4.00049 12 4.00049C16.41 4.00049 20 7.59049 20 12.0005C20 16.4105 16.41 20.0005 12 20.0005ZM16.59 7.58049L10 14.1705L7.41 11.5905L6 13.0005L10 17.0005L18 9.00049L16.59 7.58049Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
}
