import React from 'react';
import Highlighter from './components/highlighter';

export default function SeamlessIntegration() {
    return (
        <div className='rmc-bg-primary-4 pt-[60px]'>
            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='col-span-2 md:col-span-1'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-05.webp'}
                        srcSet={'../img-for_your_reader-05@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                </div>
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Seamless Integration, Privacy-First</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Easily integrate Uniqrate’s widget into your documentation without invasive cookies or distracting your readers. Whether you’re working on an internal knowledge base or public documentation, Uniqrate’s privacy-first approach makes gathering actionable insights easy and reliable.
                    </p>
                </div>
                
            </div>
        </div>
    );
}