import Island from '../svgComponents/island';
import { Bolt, ColorLens, Power, Settings, Spoke, Start } from '../svgComponents/powerful-island';
import { useEffect } from 'react';

export default function PowerfulIsland() {
    useEffect(() => {
        const video: any = document.getElementById('powerfulIslandVideo');
        video.muted = true;
        const playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    // Autoplay started successfully
                    console.log('Autoplay started');
                })
                .catch((error: any) => {
                    // Autoplay was prevented
                    // You can show a play button or some message to the user
                    console.log('Autoplay prevented:', error);
                });
        }
    }, []);

    return (
        <div className={'px-40 pt-[120px] pb-[120px] rmc-bg-primary-4 max-lg:px-8'}>
            <div className={'max-w-[1240px] m-auto'}>
                <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center  m-auto mb-6 leading-none'}>
                    Powerful Island
                </h1>
                <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center max-w-3xl mb-12'}>
                    Perfectly integrated in your website with a polished design.
                    <br />
                    All the settings you need to adjust your readers.
                    <br />
                    To fit your understanding needs.
                </p>
                <Island className={'m-auto mb-20 max-md:max-w-[100%] max-md:mb-12'} />
                <div className={'flex gap-6 mb-20 flex-wrap w-full justify-between'}>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Spoke className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Polished Design</p>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Power className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Plug & Play</p>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <ColorLens className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Custom Theme</p>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Start className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Smart Triggers</p>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Bolt className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Light Module</p>
                    </div>
                    <div
                        className={
                            'rmc-bg-secondary-2 p-4 pr-6 flex gap-6 rounded-[56px] items-center w-[31%] max-lg:w-[45%] max-md:w-full'
                        }
                    >
                        <div className={'h-20 w-20 rounded-full rmc-bg-base-1 flex justify-center'}>
                            <Settings className={'m-auto'} />
                        </div>
                        <p className={'font-semibold text-xl leading-5'}>Settings Overview</p>
                    </div>
                </div>
                <div className={'flex flex-col gap-16'}>
                    <div className={'flex gap-16 max-lg:hidden'}>
                        <div className="aspect-video w-full m-auto">
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                className="w-full object-cover rounded-3xl"
                                id="powerfulIslandVideo"
                            >
                                <source
                                    src="https://customer-i42w4b3j99jshth5.cloudflarestream.com/c82fe9d3aab1a0594ec1338d8a81c1b6/downloads/default.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                        <div className={'flex flex-col gap-6 justify-center'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                A beautiful island - Fully customizable
                            </h1>
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Our polished “island” integrates directly with your website, offering a custom theme and
                                smart triggers to gather feedback without disrupting the reader experience.
                            </p>
                        </div>
                    </div>
                    <div className={'flex flex-col gap-16 lg:hidden'}>
                        <div className={'flex flex-col gap-6'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                A beautiful island - Fully customizable
                            </h1>
                            <div className="aspect-video w-full m-auto">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    className="w-full h-full object-cover"
                                    id="powerfulIslandVideo"
                                >
                                    <source
                                        src="https://customer-i42w4b3j99jshth5.cloudflarestream.com/c82fe9d3aab1a0594ec1338d8a81c1b6/downloads/default.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Our polished “island” integrates directly with your website, offering a custom theme and
                                smart triggers to gather feedback without disrupting the reader experience.
                            </p>
                        </div>
                    </div>
                    <div className={'flex gap-16 max-lg:hidden'}>
                        <div className={'flex flex-col gap-6 justify-center'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                One Second Feedback
                            </h1>
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Reader start with one click feedback by rating - one sentence comment - one gift to
                                thank you him. He can stop at each step.
                            </p>
                        </div>
                        <img
                            loading="lazy"
                            src={'/img-powerful_island-01.webp'}
                            srcSet={'/img-powerful_island-01@2x.webp 2x'}
                            alt={'Island Vote Up black theme red background'}
                            className={'rounded-3xl m-auto'}
                        />
                    </div>
                    <div className={'flex gap-16 lg:hidden'}>
                        <div className={'flex flex-col gap-6'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                One Second Feedback
                            </h1>
                            <img
                                loading="lazy"
                                src={'/img-powerful_island-01.webp'}
                                srcSet={'/img-powerful_island-01@2x.webp 2x'}
                                alt={'Island Vote Up black theme red background'}
                                className={'rounded-3xl m-auto'}
                            />
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Reader start with one click feedback by rating - one sentence comment - one gift to
                                thank you him. He can stop at each step.
                            </p>
                        </div>
                    </div>
                    <div className={'flex gap-16 max-lg:hidden'}>
                        <img
                            loading="lazy"
                            src={'/img-powerful_island-02.webp'}
                            srcSet={'/img-powerful_island-02@2x.webp 2x'}
                            alt={'Island social proof'}
                            className={'rounded-3xl m-auto'}
                        />
                        <div className={'flex flex-col gap-6 justify-center'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                Social Proof
                            </h1>
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Build trust around your content by displaying the number of people loving it.
                            </p>
                        </div>
                    </div>
                    <div className={'flex flex-col gap-16 lg:hidden'}>
                        <div className={'flex flex-col gap-6'}>
                            <h1
                                className={
                                    'font-semibold rmc-text-base-1 text-[39px] leading-[39px] tracking-[-0.85px]'
                                }
                            >
                                Social Proof
                            </h1>
                            <img
                                loading="lazy"
                                src={'/img-powerful_island-02.webp'}
                                srcSet={'/img-powerful_island-02@2x.webp 2x'}
                                alt={'Island social proof'}
                                className={'rounded-3xl m-auto'}
                            />
                            <p className={'rmc-text-base-1 leading-[25.6px] font-normal text-base'}>
                                Build trust around your content by displaying the number of people loving it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
