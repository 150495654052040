import React from 'react';
import Highlighter from './components/highlighter';
import { Link } from 'react-router-dom';

export default function TurnDataIntoGrowth() {
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    return (
        <div className='rmc-bg-primary-4 pt-[60px]'>
            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] flex justify-center">
                <div className='flex flex-col gap-6'>
                    <h2 className="text-center text-[#2a2728] text-[61px] font-extrabold leading-[61px]">
                        <Highlighter colored={true}>Turn data into growth</Highlighter>
                    </h2>
                    <p className="text-xl text-center rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        You’ll know what type of writing style readers respond to best, what keeps them on your page, and which posts drive more engagement. Engagement insights also help improve your blog’s SEO by keeping readers on your page longer.
                    </p>
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Find your most loved content</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Uniqrate’s analytics reveal your top-performing posts by tracking reader ratings, session times, and engagement. Instantly see which topics and styles resonate most, helping you refine your content strategy. Focus on creating more of what your audience loves to keep readers coming back.
                    </p>
                </div>
                <div className='col-span-2 md:col-span-1'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-01.webp'}
                        srcSet={'../img-for_your_reader-01@2x.webp 2x'}
                        alt={'Island social proof'}
                        className={'rounded-3xl m-auto'}
                    />
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='col-span-2 md:col-span-1 order-last md:order-first'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-04.webp'}
                        srcSet={'../img-for_your_reader-04@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                </div>
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1 order-first md:order-last'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Engage with your readers</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Go beyond basic analytics by inviting readers to rate and comment directly on your blog. Uniqrate’s feedback widget encourages interaction, helping you connect with your audience and show that you value their opinions. Use exclusive perks like ebooks to reward feedback, building a loyal, engaged community.
                    </p>
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Build a community around your blog</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Uniqrate helps you turn readers into an engaged community by inviting them to share their thoughts and feedback. Show readers you value their opinions, and reward their input with perks like exclusive content or downloadable resources. Building this two-way connection not only fosters loyalty but also keeps readers returning—creating a strong, supportive community around your blog.
                    </p>
                </div>
                <div className='col-span-2 md:col-span-1'>
                    <img
                        loading="lazy"
                        src={'../img-for_your_reader-05.webp'}
                        srcSet={'../img-for_your_reader-05@2x.webp 2x'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full'}
                    />
                </div>
            </div>

            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 pt-[120px] pb-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='col-span-2 md:col-span-1 order-last md:order-first'>
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="w-full object-cover rounded-3xl"
                        id="powerfulIslandVideo"
                    >
                        <source
                            src="https://customer-i42w4b3j99jshth5.cloudflarestream.com/c82fe9d3aab1a0594ec1338d8a81c1b6/downloads/default.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1 order-first md:order-last justify-start'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>See it in action</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                        Curious about the impact of meaningful analytics? Start your free trial today to see how Uniqrate can help take your blog from good to great by connecting with your readers in a whole new way.
                    </p>
                    <Link
                        to={signupUrl}
                        className="mt-6 sm:mt-0 font-bold text-center md:text-left text-base py-4 px-8 rmc-bg-primary-1 text-white hover:rmc-bg-primary-3 rounded-lg w-fit"
                        >
                        Start 14-day free trial
                    </Link>               
                </div>
            </div>
        </div>
    );
}