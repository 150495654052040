import { AuthorModel, SettingModel } from '../service/common/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/common/seoService';
import React from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildAuthorUrlFromPath } from '../urlBuilder';
import { getBlogComponents } from '../service/common/registerBlogComponent';

export function AuthorRenderer({ author, setting }: { author: AuthorModel; setting: SettingModel }) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl = rootUrl + buildAuthorUrlFromPath(author.locale as string, author.path as string, setting);
    const Author = getBlogComponents().authorComponent;

    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: author.locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: getFirstWords(author.bio || '', 20),
                    title: author.firstname + ' ' + author.lastname,
                    image: seoService.defaultImage(rootUrl),
                })}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: getFirstWords(author.bio || '', 20),
                        title: author.firstname + ' ' + author.lastname,
                        image: seoService.defaultImage(rootUrl),
                    },
                    {
                        siteAccount: author.twitterAccount || seoService.defaultTwitterSiteAccount(),
                        creatorAccount: seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>
            <div>
                <SectionsRenderer sections={setting.blogSectionsBefore} locale={author.locale as string} />
                <Author url={pageUrl} author={author} />
                <SectionsRenderer sections={setting.blogSectionsAfter} locale={author.locale as string} />
            </div>
        </div>
    );
}

// Function that get 200 first words of a string
function getFirstWords(str: string, n: number) {
    return str.split(' ').slice(0, n).join(' ');
}
