import { useEffect } from 'react';

export default function VideoDemo() {
    useEffect(() => {
        const video: any = document.getElementById('myVideo');
        video.muted = true;
        const playPromise = video.play();

        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    // Autoplay started successfully
                    console.log('Autoplay started');
                })
                .catch((error: any) => {
                    // Autoplay was prevented
                    // You can show a play button or some message to the user
                    console.log('Autoplay prevented:', error);
                });
        }
    }, []);

    return (
        <div className={'rmc-bg-primary-4 pt-20 px-40 max-lg:px-0'}>
            <div className="rmc-bg-secondary-2 max-lg:rmc-bg-primary-4  m-auto rounded-[40px] max-w-[1240px] max-lg:rounded-[0px]">
                <div className="aspect-video w-full m-auto">
                    <video autoPlay loop muted playsInline className="w-full h-full object-cover" id="myVideo">
                        <source
                            src="https://customer-i42w4b3j99jshth5.cloudflarestream.com/d61e203ad8b5d9767fd3015835338ba5/downloads/default.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
        </div>
    );
}
