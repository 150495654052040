import { ArticleModel, SettingModel } from '../service/database';
import Markdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeShiftHeading from 'rehype-shift-heading';
// @ts-ignore
import rehypeFigure from 'rehype-figure';
import { ShareButtons } from '../sections/components/shareButtons';
import { Link } from 'react-router-dom';
import { buildCategoryUrlFromPath } from '../urlBuilder';

export function Article({ url, article, setting }: { url: string; article: ArticleModel; setting: SettingModel }) {
    const articleSummaryTitles = [...(article.content || '')?.matchAll(/(^#+\s)(.*)/gm)];

    return (
        <div className="rmc-bg-primary-4 w-full px-40 max-xl:px-8">
            <div className={'max-w-[1420px] m-auto'}>
                <header className="flex flex-col gap-6 pt-24 items-center">
                    <div className="flex gap-2 items-center leading-5">
                        <img
                            loading="lazy"
                            src="/default-avatar.png"
                            alt="user avatar"
                            className="h-10 w-10 rounded rounded-full"
                        />
                        <span className={'leading-[25.6px] rmc-text-base-1'}>
                            {article.author.firstname} {article.author.lastname}
                        </span>
                        <span className={'rmc-text-base-2 text-[13px] leading-[20.8px]'}>{article.author.role}</span>
                    </div>
                    <h1 className="text-2xl lg:text-5xl flex-1 text-center font-semibold rmc-text-base-1">
                        {article.title}
                    </h1>
                    <Link
                        to={buildCategoryUrlFromPath(
                            article.category.locale as string,
                            article.category.path as string,
                            setting,
                        )}
                        className="relative z-10 rounded-full rmc-bg-base-1 px-3 py-1.5 font-medium text-sm text-white hover:rmc-bg-neutral-2 mb-14 max-lg:mb-0"
                    >
                        {article.category.name}
                    </Link>
                    <section className="py-2 flex items-center justify-center gap-2 lg:hidden mb-6">
                        <ShareButtons
                            url={url}
                            title={article.title}
                            twitterAccount={article.seo.twitter?.siteAccount}
                        />
                    </section>
                </header>
                <section className="flex">
                    <aside className="rmc-bg-primary-4 w-80 mx-8 hidden lg:flex lg:flex-col rounded-3xl">
                        <section className="rmc-bg-secondary-2 rounded-t-3xl">
                            <div className="px-4 py-5 lg:p-6">
                                <h3 className="text-base font-semibold leading-4 text-gray-900">Content</h3>
                                <div className="mt-4 lg:flex lg:items-start lg:justify-between">
                                    <div className="text-sm text-gray-700">
                                        <ul className="list-decimal ml-4">
                                            {articleSummaryTitles.map((title, index) => (
                                                <li key={index} className={'my-2'}>
                                                    <a
                                                        href={`#${title[2]
                                                            .replace(/ /g, '-')
                                                            .replace(/[^\w-]+/g, '')
                                                            .toLowerCase()}`}
                                                    >
                                                        {title[2]}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="rmc-bg-secondary-2 rounded-b-3xl w-80 p-2 pt-0 card-shadow">
                            {(article.image || article.seo?.image) && (
                                <img
                                    loading="lazy"
                                    className="rounded rounded-xl"
                                    src={article.image || article.seo.image}
                                    alt={article.title + ' image.'}
                                />
                            )}
                        </section>
                        <section className="w-80 p-2 mt-4 flex items-center justify-center gap-2">
                            <div>Share</div>
                            <ShareButtons
                                url={url}
                                title={article.title}
                                twitterAccount={article.seo.twitter?.siteAccount}
                            />
                        </section>
                    </aside>
                    <article className="flex-1 rmc-bg-primary-4 mx-auto max-w-prose mb-16 lg:rounded-lg lg:mr-8 lg:ml-0">
                        <div className="px-4 py-5 lg:p-6">
                            <section className="reader">
                                <Markdown
                                    rehypePlugins={[
                                        rehypeSlug,
                                        [rehypeShiftHeading, { shift: 1 }],
                                        [rehypeFigure, { className: 'caption' }],
                                    ]}
                                >
                                    {article.content}
                                </Markdown>
                            </section>
                        </div>
                    </article>
                </section>
            </div>
        </div>
    );
}
