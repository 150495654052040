import { DocumentationMenuModel, DocumentationModel, SettingModel } from '../service/common/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/common/seoService';
import React from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildDocumentationUrlFromPath } from '../urlBuilder';
import { Documentation } from '../docTheme/documentation';
import { DocumentationMenu } from '../docTheme/documentationMenu';

export function DocumentationRenderer({
    documentation,
    documentationMenu,
    setting,
}: {
    documentation: DocumentationModel;
    documentationMenu: DocumentationMenuModel;
    setting: SettingModel;
}) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl =
        rootUrl + buildDocumentationUrlFromPath(documentation.locale as string, documentation.path as string, setting);

    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: documentation.locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: getFirstWords(documentation.content || '', 20),
                    title: documentation.title,
                    image: seoService.defaultImage(rootUrl),
                })}
                {seoService.opengraphArticleMetaTags({
                    publishedAt: documentation.publishedAt,
                    updatedAt: documentation.updatedAt,
                })}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: getFirstWords(documentation.content || '', 20),
                        title: documentation.title,
                    },
                    {
                        siteAccount: seoService.defaultTwitterSiteAccount(),
                        creatorAccount: seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>
            <div>
                <DocumentationMenu documentationMenu={documentationMenu} setting={setting}></DocumentationMenu>
                <SectionsRenderer sections={setting.docSectionsBefore} locale={documentation.locale as string} />
                <Documentation url={pageUrl} setting={setting} documentation={documentation} />
                <SectionsRenderer sections={setting.docSectionsAfter} locale={documentation.locale as string} />
            </div>
        </div>
    );
}

// Function that get 200 first words of a string
function getFirstWords(str: string, n: number) {
    return str.split(' ').slice(0, n).join(' ');
}
