import React, { useState } from 'react';
import './sidebar.css';
import { DocumentationMenuItemModel, DocumentationMenuModel, SettingModel } from '../service/common/database';
import { buildDocumentationUrlFromPath } from '../urlBuilder';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

export function DocumentationMenu({
    documentationMenu,
    setting,
}: {
    documentationMenu: DocumentationMenuModel;
    setting: SettingModel;
}) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // FIXME From settings in Hygraph
    const companyName = setting.siteName;
    const companyLogo = setting.siteLogo;

    const menuWithLevels: { level: number; item: DocumentationMenuItemModel }[] = [];
    const extractMenuItems = (currentLevel: number, currentItem: DocumentationMenuItemModel): void => {
        menuWithLevels.push({ level: currentLevel, item: currentItem });
        if (currentItem.childDocumentations.length > 0) {
            for (const childItem of currentItem.childDocumentations) {
                extractMenuItems(currentLevel + 1, childItem);
            }
        }
    };

    for (const item of documentationMenu) {
        extractMenuItems(0, item);
    }

    const currentPathIndex = menuWithLevels
        .map((menu, index) => {
            return { index: index, path: menu.item.path, item: menu.item };
        })
        .filter(
            (indexedPath) =>
                window.location.pathname ===
                buildDocumentationUrlFromPath(indexedPath.item.locale as string, indexedPath.path as string, setting),
        )[0]?.index;

    console.log(currentPathIndex);

    return (
        <>
            {/* Open responsive sandwich icon */}
            <div className="absolute top-0 right-2 md:hidden">
                <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>

            {/* Static sidebar for desktop */}
            <aside className="hidden md:fixed md:left-0 md:top-0 md:bottom-0 md:z-50 md:flex md:w-72 md:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 bg-gray-100">
                    <div className="flex h-40 shrink-0 items-center relative">
                        <Link to="/docs" className="flex-1">
                            <span className="sr-only">{companyName}</span>
                            <img
                                loading="lazy"
                                className="w-1/6 mx-auto"
                                src={companyLogo}
                                alt={`logo ${companyName}`}
                            />
                        </Link>
                        <div>
                            <img
                                loading="lazy"
                                className="w-60 opacity-50 absolute -top-5 right-0 blur-3xl"
                                src={companyLogo}
                                alt={`logo ${companyName}`}
                            />
                        </div>
                    </div>

                    <nav className="hidden md:block">
                        <h2 className="text-sm font-semibold text-zinc-900 dark:text-white">Documentation</h2>

                        <ul>
                            <li className="relative mt-6 md:mt-0 flex-1">
                                <div className="relative mt-3 pl-2">
                                    <div className="absolute inset-x-0 top-0 bg-zinc-800/2.5 will-change-transform dark:bg-white/2.5"></div>
                                    <div className="absolute inset-y-0 left-2 w-[2px] bg-zinc-900/10 dark:bg-white/5"></div>
                                    <div
                                        className="absolute left-2 h-[28px] w-[2px] bg-orange-500"
                                        style={{ top: `${(currentPathIndex || 0) * 28}px` }}
                                    ></div>
                                    <ul className="border-l border-transparent">
                                        {menuWithLevels.map((itemWithLevel, index) => (
                                            <li
                                                key={index}
                                                style={{ paddingLeft: `${itemWithLevel.level * 16}px` }}
                                                className="relative"
                                            >
                                                <Link
                                                    aria-current="page"
                                                    className="flex justify-between gap-2 py-1 pr-3 text-sm transition pl-4 text-zinc-900 dark:text-white"
                                                    to={buildDocumentationUrlFromPath(
                                                        itemWithLevel.item.locale as string,
                                                        itemWithLevel.item.path as string,
                                                        setting,
                                                    )}
                                                >
                                                    <span className="truncate" title={itemWithLevel.item.title}>
                                                        {itemWithLevel.item.title}
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                            <li className="bottom-0 absolute  right-0 left-0">
                                <Link
                                    to="/"
                                    target="_blank"
                                    className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                >
                                    <span aria-hidden="true">{companyName}</span>
                                </Link>
                                <Link
                                    to="/blog"
                                    target="_blank"
                                    className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                >
                                    <span aria-hidden="true">Blog</span>
                                </Link>
                                <Link
                                    to="/docs"
                                    className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                >
                                    <span aria-hidden="true">Documentation</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="h-screen flex">
                        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100">
                            <div className="flex h-20 shrink-0 items-center relative">
                                {/* Logo */}
                                <Link to="/docs" className="flex-1">
                                    <span className="sr-only">{companyName}</span>
                                    <img
                                        loading="lazy"
                                        className="w-10 mx-auto"
                                        src={companyLogo}
                                        alt={`logo ${companyName}`}
                                    />
                                </Link>
                                <div>
                                    <img
                                        loading="lazy"
                                        className="w-60 opacity-50 absolute -top-5 right-0 blur-3xl"
                                        src={companyLogo}
                                        alt={`logo ${companyName}`}
                                    />
                                </div>

                                {/* Close responsive menu X icon */}
                                <div className="absolute top-2 right-0 flex">
                                    <button
                                        type="button"
                                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>

                            <nav>
                                <h2 className="text-sm font-semibold text-zinc-900 dark:text-white">Documentation</h2>

                                <ul>
                                    <li className="relative mt-6 md:mt-0 flex-1">
                                        <div className="relative mt-3 pl-2">
                                            <div className="absolute inset-x-0 top-0 bg-zinc-800/2.5 will-change-transform dark:bg-white/2.5"></div>
                                            <div className="absolute inset-y-0 left-2 w-[2px] bg-zinc-900/10 dark:bg-white/5"></div>
                                            <div
                                                className="absolute left-2 h-[28px] w-[2px] bg-orange-500"
                                                style={{ top: `${(currentPathIndex || 0) * 28}px` }}
                                            ></div>
                                            <ul className="border-l border-transparent">
                                                {menuWithLevels.map((itemWithLevel, index) => (
                                                    <li
                                                        key={index}
                                                        style={{ paddingLeft: `${itemWithLevel.level * 16}px` }}
                                                        className="relative"
                                                    >
                                                        <Link
                                                            aria-current="page"
                                                            className="flex justify-between gap-2 py-1 pr-3 text-sm transition pl-4 text-zinc-900 dark:text-white"
                                                            to={buildDocumentationUrlFromPath(
                                                                itemWithLevel.item.locale as string,
                                                                itemWithLevel.item.path as string,
                                                                setting,
                                                            )}
                                                        >
                                                            <span className="truncate" title={itemWithLevel.item.title}>
                                                                {itemWithLevel.item.title}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="bottom-0 absolute  right-0 left-0">
                                        <Link
                                            to="/"
                                            target="_blank"
                                            className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                        >
                                            <span aria-hidden="true">{companyName}</span>
                                        </Link>
                                        <Link
                                            to="/blog"
                                            target="_blank"
                                            className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                        >
                                            <span aria-hidden="true">Blog</span>
                                        </Link>
                                        <Link
                                            to="/docs"
                                            className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                        >
                                            <span aria-hidden="true">Documentation</span>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    );
}
