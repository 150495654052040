import React from 'react';
import Highlighter from './components/highlighter';

export default function CompatibleWith() {
    return (
        <div className='rmc-bg-primary-4 pt-[60px]'>
            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 py-[60px] grid grid-cols-2 gap-6 md:gap-20 items-center">
                <div className='flex flex-col gap-6 col-span-2 md:col-span-1'>
                    <h3 className="tracking-normal text-left text-[39px] font-semibold leading-none tracking-tight rmc-text-base-1">
                        <Highlighter colored={true}>Compatible with popular platforms</Highlighter>
                    </h3>
                    <p className="text-xl rmc-text-base-1 max-w-2xl leading-8 font-normal">
                    Uniqrate seamlessly integrates with WordPress, Webflow, Ghost, Wix, and many other popular blogging platforms, making it easy to get started.
                    </p>
                </div>
                <div className='flex flex-col place-items-start col-span-2 md:col-span-1'>
                    <img
                        loading="lazy"
                        src={'/compatible-with.svg'}
                        alt={'Ratings on island black theme'}
                        className={'h-auto w-full saturate-0'}
                    />
                </div>
            </div>
        </div>
    );
}