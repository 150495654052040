import {
    ArticleModel,
    AuthorModel,
    CategoryModel,
    DatabaseImplementation,
    DocumentationMenuModel,
    DocumentationModel,
    PageModel,
    SettingModel,
    Status,
} from '../database';
import {
    mapToArticleModel,
    mapToAuthorModel,
    mapToCategoryModel,
    mapToDocumentationMenuItemModel,
    mapToDocumentationModel,
    mapToPageModel,
    mapToSettingModel,
} from '../mappers';

/**
 * FileDb is a database implementation that reads static data on an uri
 * These data are stored in a json file fixed at the build time
 * These data come from dynamic db content
 */
class FileDb implements DatabaseImplementation {
    private readonly fileDbPath = '/generatedFileDb';

    constructor(private readonly fetch: any) {
        console.log('Database implementation - ', 'FileDb');
    }

    /* --- Setting (global website settings) --- */

    async getSetting(status: Status): Promise<SettingModel> {
        console.log('filebd.getSetting');
        const settings: any = await this.fetch(`${this.fileDbPath}/settings.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(settings);
        return this.mapToSettingModel(settings);
    }

    /* Documentations (blog) */

    async getDocumentations(): Promise<DocumentationModel[]> {
        console.log('filebd.getDocumentations');
        const allDocumentations: any = await this.fetch(`${this.fileDbPath}/documentations.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log('allDocumentations -- ', allDocumentations);
        return allDocumentations.map((documentation: any) => this.mapToDocumentationModel(documentation));
    }

    async getDocumentationBySlug(path: string): Promise<DocumentationModel> {
        console.log('filebd.getDocumentationBySlug');
        const { documentation } = (await this.fetch(
            `${this.fileDbPath}/documentations-${path.replace('/', '--')}.json`,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            },
        ).then((res: any) => res.json())) as any;
        console.log(documentation);
        return this.mapToDocumentationModel(documentation);
    }

    async getDocumentationMenuItems(): Promise<DocumentationMenuModel> {
        console.log('filebd.getDocumentationMenuItems');
        const allMenuItems: any = await this.fetch(`${this.fileDbPath}/documentations-menu-items.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allMenuItems);
        return allMenuItems.map((documentation: any) => this.mapToDocumentationMenuItemModel(documentation));
    }

    /* Articles (blog) */

    async getArticles(): Promise<ArticleModel[]> {
        console.log('filebd.getArticles');
        const allArticles: any = await this.fetch(`${this.fileDbPath}/articles.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allArticles);
        return allArticles.map((article: any) => this.mapToArticleModel(article));
    }

    async getArticleBySlug(path: string): Promise<ArticleModel> {
        console.log('filebd.getArticleBySlug');
        const { article } = (await this.fetch(`${this.fileDbPath}/articles-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(article);
        return this.mapToArticleModel(article);
    }

    /* Authors (blog) */

    async getAuthors(): Promise<AuthorModel[]> {
        console.log('filebd.getAuthors');
        const allAuthors: any = await this.fetch(`${this.fileDbPath}/authors.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allAuthors);
        return allAuthors.map((author: any) => this.mapToAuthorModel(author));
    }

    async getAuthorBySlug(path: string): Promise<AuthorModel> {
        console.log('filebd.getAuthorBySlug');
        const { author } = (await this.fetch(`${this.fileDbPath}/authors-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(author);
        return this.mapToAuthorModel(author);
    }

    /* Categories (blog) */

    async getCategories(): Promise<CategoryModel[]> {
        console.log('filebd.getCategories');
        const allCategories: any = await this.fetch(`${this.fileDbPath}/categories.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allCategories);
        return allCategories.map((category: any) => this.mapToCategoryModel(category));
    }

    async getCategoryBySlug(path: string): Promise<CategoryModel> {
        console.log('filebd.getCategoryBySlug');
        const { category } = (await this.fetch(`${this.fileDbPath}/categories-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(category);
        return this.mapToCategoryModel(category);
    }

    /* Pages (landing) */

    async getPages(): Promise<PageModel[]> {
        console.log('filebd.getPages');
        const allPages: any = await this.fetch(`${this.fileDbPath}/pages.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allPages);
        return allPages.map((page: any) => this.mapToPageModel(page));
    }

    async getPageBySlug(path: string): Promise<PageModel> {
        console.log('filebd.getPageBySlug');
        const { page } = (await this.fetch(`${this.fileDbPath}/pages-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(page);
        return this.mapToPageModel(page);
    }

    /* --- Mapping functions --- */

    private mapToPageModel(page: any) {
        return mapToPageModel(page);
    }

    private mapToDocumentationModel(documentation: any) {
        return mapToDocumentationModel(documentation);
    }

    private mapToDocumentationMenuItemModel(documentation: any) {
        return mapToDocumentationMenuItemModel(documentation);
    }

    private mapToArticleModel(article: any) {
        return mapToArticleModel(article);
    }

    private mapToAuthorModel(author: any) {
        return mapToAuthorModel(author);
    }

    private mapToCategoryModel(category: any) {
        return mapToCategoryModel(category);
    }

    private mapToSettingModel(setting: any) {
        return mapToSettingModel(setting);
    }
}

export default FileDb;
