import React from 'react';
import { Link } from 'react-router-dom';

export default function StartInLessThanMinute() {
  const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    return (
      <div className='rmc-bg-primary-4 pt-[60px]'>
        <div className="mx-auto max-w-[1240px] px-8 lg:px-0 py-[60px] flex flex-col gap-16">
          <div className='grid grid-cols-1 sm:grid-cols-2 items-end'>
            <div className="flex flex-col gap-6">
              <div className={'w-fit flex rmc-bg-green-3 py-2 px-3 gap-2 justify-center items-center rounded-[30px]'}>
                    <span className={'text-xs font-semibold'}>PLUG & PLAY</span>
                </div>
              <h2 className="text-left text-[#2a2728] text-[61px] font-extrabold leading-[61px]">Start under 1 minute</h2>
              <p className="text-left text-[#2a2728] text-xl font-normal leading-8">With an easy plug and play integration, just copy snippet code on your content to add Uniqrate widget. Discover what your readers truly think today.</p>
            </div>
            <div className='h-full flex place-content-end items-end'>
              <Link
                  to={signupUrl}
                  className="mt-6 sm:mt-0 font-bold text-center md:text-left text-base py-4 px-8 rmc-bg-primary-1 text-white hover:rmc-bg-primary-3 rounded-lg max-md:w-full relative"
                  >
                  Start 14-day free trial
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
              {/* 1 */}
              <div className="flex-col col-span-1 md:col-span-3 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#fcedd8] bg-gradient-to-b from-[#fdeed8] to-transparent flex-col gap-2">
                  <div className="text-[#2a2728 text-[25px] font-semibold leading-[25px]">1. Add the widget</div>
                  <p className="text-[#2a2728 font-normal leading-relaxed">
                  Uniqrate’s lightweight widget fits right into your blog with just a few lines of code. It’s easy to install and doesn’t disrupt your readers’ experience.
                  </p>
                </div>
                <div className="h-full bg-[#fcedd8] p-4">
                  <img
                    loading="lazy"
                    src={'/img-start-less-01.png'}
                    srcSet={'/img-start-less-01@2x.png 2x'}
                    alt={'Ratings on island black theme'}
                    className={'h-auto w-full rounded-[12px]'}
                  />  
                </div>
              </div>

              {/* 2 */}
              <div className="flex-col col-span-1 md:col-span-2 rounded-[20px] overflow-hidden min-h-[492px] justify-end">
                <div className="bg-[#2a2728] p-4">
                  <img
                    loading="lazy"
                    src={'../inbox.webp'}
                    srcSet={'../inbox@2x.webp 2x'}
                    alt={'Ratings on island black theme'}
                    className={'h-auto w-full rounded-[12px]'}
                  />
                </div>
                <div className="flex p-8 bg-[#2a2728] bg-gradient-to-t from-black to-transparent flex-col gap-2 h-full">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">2. Collect reader feedback</div>
                  <p className="text-white font-normal leading-relaxed">
                    Readers can rate and comment directly on your content. This real-time feedback gives you insights into what’s working and what could be improved, making it easy to adjust and grow your blog over time.
                  </p>
                </div>
              </div>

              {/* 3 */}
              <div className="flex-col col-span-1 md:col-span-2 rounded-[20px] overflow-hidden min-h-[492px]">
                <div className="flex p-8 bg-[#fcedd8] bg-gradient-to-b from-[#fdeed8] to-transparent flex-col gap-2">
                  <div className="text-[#2a2728 text-[25px] font-semibold leading-[25px]">3. Analyze reader data</div>
                  <p className="text-[#2a2728 font-normal leading-relaxed">
                    Our intuitive dashboard shows key reader behaviors—like top-engaged posts and time spent—offering all insights in one place. Track readers' journeys to understand their interests and engagement patterns.
                  </p>
                </div>
                <div className="h-full bg-[#fcedd8] p-4">
                  <img
                    loading="lazy"
                    src={'../content.webp'}
                    srcSet={'../content@2x.webp 2x'}
                    alt={'Ratings on island black theme'}
                    className={'h-auto w-full rounded-[12px]'}
                  />  
                </div>
              </div>

              {/* 4 */}
              <div className="flex-col col-span-1 md:col-span-3 rounded-[20px] overflow-hidden min-h-[492px] justify-end">
                <div className="bg-[#2a2728] p-4">
                  <img
                    loading="lazy"
                    src={'../home.webp'}
                    srcSet={'../home@2x.webp 2x'}
                    alt={'Ratings on island black theme'}
                    className={'h-auto w-full rounded-[12px]'}
                  />
                </div>
                <div className="flex p-8 bg-[#2a2728] bg-gradient-to-t from-black to-transparent flex-col gap-2 h-full">
                  <div className="text-white text-[25px] font-semibold leading-[25px]">4. Take action</div>
                  <p className="text-white font-normal leading-relaxed">
                    Armed with data, you can make targeted adjustments to your content, find your winning writing style, and create a blog experience that readers connect with.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}