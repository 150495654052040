import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CheckCircle from '../icons/check-circle';
import PaymentMethods from './components/payment-methods';
import ToggleSwitch from './components/ToggleSwitch';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import {PRICES} from "../service/uniqrate/prices";

interface PricingProps {
    selectPlan: (plan: string) => {};
}

export default function Pricing({ selectPlan }: PricingProps) {
    const location = useLocation();
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    const [monthlyPageViews, setMonthlyPageViews] = useState(5000);
    const [isYearlyPricing, setIsYearlyPricing] = useState(false);

    const displayMonthlyPageViews = () => {
        if (monthlyPageViews === 105000) {
            return '100k+';
        }
        return `${monthlyPageViews / 1000}k`;
    };

    const getCustomLink = () => {
        if (monthlyPageViews === 105000) {
            return 'https://tally.so/r/wLYl1y';
        } else {
            return signupUrl;
        }
    };

    const getPrice = () => {
        if (isYearlyPricing) {
            return `${Math.round(PRICES[monthlyPageViews.toString()].yearly / 12)}`;
        } else {
            return `${PRICES[monthlyPageViews.toString()].monthly}`;
        }
    };

    const getMonthlyPrice = () => {
        return `${PRICES[monthlyPageViews.toString()].monthly}`;
    };

    const getLifetimePrice = () => {
        return `${PRICES[monthlyPageViews.toString()].monthly * 12 * 2}`;
    };

    return (
        <div className={`rmc-bg-primary-4 px-40 pt-16 max-lg:px-0 ${location.pathname === '/pricing' && 'pb-[120px]'}`}>
            <div className={'max-w-[1240px] m-auto flex flex-col gap-6 max-lg:gap-2'}>
                <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center m-auto leading-none max-lg:px-8'}>
                    Plans that match your growth
                </h1>
                <p className={'text-xl text-center rmc-text-base-1 leading-8 mb-4'}>Sign up for 14-day free trial</p>
                <div className="mt-6 rmc-bg-secondary-2 rounded-3xl max-md:rounded-none flex py-2 px-4 gap-16 h-[48px] items-center max-md:flex-col max-md:h-auto max-md:gap-4">
                    <p className={'w-[40%] text-base leading-6 max-md:w-[95%] max-md:m-auto max-md:text-center'}>
                        Up to <span className={'font-bold'}>{displayMonthlyPageViews()}</span> monthly pageviews
                    </p>
                    <div className={'flex gap-4 items-center w-[70%] max-md:w-[90%]'}>
                        <label htmlFor="views" className={'text-[13px] text-base leading-5'}>
                            5k
                        </label>
                        <input
                            id="views"
                            type="range"
                            min="5000"
                            max="105000"
                            step="5000"
                            value={monthlyPageViews}
                            onChange={(e) => setMonthlyPageViews(Number(e.target.value))}
                            className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
                        />
                        <span className={'text-[13px] text-base leading-5'}>100k+</span>
                    </div>
                </div>
                <div className={'flex gap-4 max-[1220px]:flex-col'}>
                    <div
                        className={
                            'w-1/3 rounded-3xl max-md:rounded-none flex flex-col p-[50px] bg-white card-shadow max-[1220px]:w-full min-[1220px]:mt-[3.4rem]'
                        }
                    >
                        <p className={'text-xl font-bold mb-[13px] rmc-text-base-1'}>Personal</p>
                        <p className={'font-bold rmc-text-base-1 text-[42px] mb-5'}>Free</p>
                        <Link
                            target={'_blank'}
                            to={signupUrl}
                            //onClick={() => selectPlan(`${monthlyPageViews / 1000}k-monthly`)}
                            className={
                                'text-center py-4 px-8 rmc-bg-primary-1 rounded-lg text-lg w-full font-bold hover:rmc-bg-primary-3 mb-[30px] text-white leading-6 tracking-[0.4px]'
                            }
                        >
                            Start for free
                        </Link>
                        <div className={'flex flex-col gap-[13px]'}>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>
                                    <span className={'font-bold flex items-center'}>
                                        {' '}
                                        For personal use only{'  '}
                                        <p className={'has-tooltip flex relative'}>
                                            <span className="tooltip rounded shadow-lg p-1 text-xs rmc-bg-primary-4 rmc-text-base-1 w-[150px] left-[25px] max-md:-left-[100px] max-md:top-[25px]">
                                                For freelancers, personal blogs and open source projects. Are excluded
                                                agencies, landing pages and commercial uses
                                            </span>
                                            <QuestionMarkCircleIcon className={'w-5 h-5 ml-1'} />
                                        </p>
                                    </span>
                                </p>
                            </div>
                            <div className={'flex gap-2  items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Unlimited feedbacks</p>
                            </div>
                            <div className={'flex gap-2  items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Unlimited engagements</p>
                            </div>
                            <div className={'flex gap-2  items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Analytics dashboard</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Inbox</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            'w-1/3 rounded-3xl max-md:rounded-none flex flex-col rmc-bg-base-1 p-[50px] card-shadow max-[1220px]:w-full max-md:border-none'
                        }
                    >
                        <ToggleSwitch
                            enabled={isYearlyPricing}
                            onChange={() => {
                                setIsYearlyPricing(!isYearlyPricing);
                            }}
                            textColor={'text-white'}
                            label={'Annual billing (2 months free)'}
                        />
                        <div className={' flex text-xl font-bold mb-[13px] text-white gap-2'}>
                            Business
                            <p
                                className={
                                    'rounded-[30px] bg-[#13C296] text-white py-[3px] px-[10px] text-[13px] font-bold leading-5'
                                }
                            >
                                Popular
                            </p>
                        </div>
                        <p className={'font-bold text-white text-[42px] mb-5'}>
                            {monthlyPageViews === 105000 ? (
                                'Custom'
                            ) : (
                                <span className={'text-white'}>
                                    {' '}
                                    <p
                                        className={`text-base rmc-text-base-2 font-semibold line-through mr-1 ${isYearlyPricing ? 'inline' : 'hidden'}`}
                                    >
                                        ${getMonthlyPrice()}
                                    </p>
                                    ${getPrice()}
                                    <span className={'font-normal text-base rmc-text-base-2 leading-[25.6px]'}>
                                        / month
                                    </span>
                                </span>
                            )}
                        </p>
                        <Link
                            target={'_blank'}
                            to={getCustomLink()}
                            //onClick={() => selectPlan(`${monthlyPageViews / 1000}k-yearly`)}
                            className={
                                'text-center py-4 px-8 rmc-bg-primary-1 rounded-lg text-lg w-full font-bold hover:rmc-bg-primary-3 mb-[30px] text-white leading-6 tracking-[0.4px]'
                            }
                        >
                            {monthlyPageViews === 105000 ? 'Contact us' : 'Start 14-day free trial'}
                        </Link>
                        <div className={'flex flex-col gap-[13px]'}>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'text-base text-white font-bold'}>
                                    {isYearlyPricing ? 'Yearly' : 'Monthly'} engagement
                                </p>
                            </div>
                            <div className={'flex gap-2'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-bold text-base text-white'}>
                                    <span className={'font-bold'}>
                                        {' '}
                                        {monthlyPageViews === 105000 ? '100' : monthlyPageViews / 1000}k
                                        {monthlyPageViews === 105000 && '+'}
                                    </span>{' '}
                                    monthly pageviews
                                </p>
                            </div>

                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base text-white'}>14-day free trial</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base text-white'}>Unlimited feedbacks</p>
                            </div>

                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base text-white'}>Unlimited engagements</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base text-white'}>Analytics dashboard</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base text-white'}>Inbox</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`w-1/3 rounded-3xl max-md:rounded-none flex flex-col p-[50px] ${monthlyPageViews >= 30000 ? 'rmc-bg-secondary-3' : 'bg-white'} card-shadow max-[1220px]:w-full min-[1220px]:mt-[3.4rem]`}
                    >
                        <p className={'text-xl font-bold mb-[13px] rmc-text-base-1 flex gap-2'}>
                            Early adopter
                            <span
                                className={
                                    'flex items-center rounded-[30px] rmc-bg-primary-5 rmc-text-base-1 py-[3px] px-[10px] text-[13px] font-bold leading-5'
                                }
                            >
                                only 98 left
                            </span>
                        </p>
                        <div
                            className={`font-bold rmc-text-base-1 text-[42px] mb-5 bg-white ${monthlyPageViews >= 30000 && 'rmc-bg-secondary-3'}`}
                        >
                            {monthlyPageViews < 30000 && (
                                <>
                                    <span
                                        className={`text-base rmc-text-base-2 font-semibold line-through mr-1 ${monthlyPageViews < 30000 ? 'inline' : 'hidden'}`}
                                    >
                                        ${getLifetimePrice()}
                                    </span>
                                    ${PRICES[monthlyPageViews.toString()].lifetime || 700}
                                </>
                            )}
                            {monthlyPageViews >= 30000 && <div className={'h-[63px]'}></div>}
                        </div>
                        {monthlyPageViews >= 30000 ? (
                            <p
                                className={
                                    'py-4 rmc-bg-secondary-3 rounded-lg text-lg w-full font-medium mb-[30px] leading-6 text-center tracking-[0.4px]'
                                }
                            >
                                Unavailable for 30k+ views
                            </p>
                        ) : (
                            <Link
                                to={signupUrl}
                                //onClick={() => selectPlan(`${monthlyPageViews / 1000}k-lifetime`)}
                                className={
                                    'py-4 px-8 rmc-bg-primary-1 rounded-lg text-lg w-full font-bold hover:rmc-bg-primary-3 mb-[30px] text-white leading-6 text-center tracking-[0.4px]'
                                }
                            >
                                Start 14-day free trial
                            </Link>
                        )}

                        <div className={'flex flex-col gap-[13px]'}>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'text-base rmc-text-base-1 font-bold'}>Lifetime access</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-bold text-base rmc-text-base-1'}>
                                    <span className={'font-bold'}>
                                        {' '}
                                        {monthlyPageViews === 105000 ? '100' : monthlyPageViews / 1000}k
                                        {monthlyPageViews === 105000 && '+'}
                                    </span>{' '}
                                    monthly pageviews
                                </p>
                            </div>

                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>14-day free trial</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Unlimited feedbacks</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Unlimited engagements</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Analytics dashboard</p>
                            </div>
                            <div className={'flex gap-2 items-start'}>
                                <CheckCircle fill={'#1BB157'} />
                                <p className={'font-normal text-base rmc-text-base-1'}>Inbox</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div
                    className={
                        'flex max-md:flex-col bg-white p-[50px] rounded-3xl gap-9 justify-between max-md:rounded-none mb-7'
                    }
                >
                    <div className={'flex flex-col gap-2'}>
                        <p className={'text-xl font-bold leading-8'}>Lifetime free plan for Open source</p>
                        <p className={'leading-6'}>
                            Lifetime free plan for Open source documentation.
                            <br />
                            Let us know, we’d be delighted to give you a free lifetime access to Uniqrate.
                        </p>
                    </div>
                    <Link
                        target={'_blank'}
                        to={'https://tally.so/r/wkWLVZ'}
                        className={
                            'py-2 px-5 rmc-bg-primary-2 rounded-lg text-base w-fit font-medium hover:rmc-bg-primary-3 h-fit max-md:self-center'
                        }
                    >
                        Apply now
                    </Link>
                </div>*/}
                <PaymentMethods />
            </div>
        </div>
    );
}
