import React, { useEffect, useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from './components/cta';
import { Link, useLocation } from 'react-router-dom';
import Highlighter from './components/highlighter';
import { Dialog, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

interface HeaderInputs {
    logo: {
        img: string;
        href: string;
    };
    logoSmall: string;
    companyName: string;
    navigation: { name: string; href: string }[];
    cta: Button;
}

export default function HeaderSignUp({ companyName, logo, logoSmall, navigation }: HeaderInputs) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();
    const [currentPathname, setCurrentPathname] = useState(location.pathname);

    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    const signinUrl = process.env.REACT_APP_SIGNIN_URL as string;

    useEffect(() => {
        setCurrentPathname(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <div className="w-100 z-50 bg-white max-w-[100vw] max-md:static">
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-4 lg:px-8 rmc-bg-primary-4" aria-label="Global">
                        <div className="flex flex-1">
                            <a href={logo.href} className="-m-1.5 p-1.5">
                                <span className="sr-only">{companyName}</span>
                                <img loading="lazy" className="h-8 w-auto" src={logo.img} alt="logo" />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-between rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden flex-1 lg:flex lg:gap-x-8 justify-center">
                            <Menu as={'div'} className="relative inline-block text-left">
                                <MenuButton className="flex  font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2">
                                    Product
                                    <img loading="lazy"  alt={''} src={'../arrow_drop_down.svg'} aria-hidden={true} />
                                </MenuButton>

                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <div className="py-1">
                                        <MenuItem>
                                            <a
                                                href="/"
                                                className="mb-2 border-b-2 border-neutral-100 block px-4 py-2 text-sm rmc-text-base-1 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:rmc-text-primary-2"
                                            >
                                                All-in-one analytics tool
                                            </a>
                                        </MenuItem>
                                        <MenuItem>
                                            <a
                                                href="/bloggers"
                                                className="block px-4 py-2 text-sm rmc-text-base-1 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:rmc-text-primary-2"
                                            >
                                                For bloggers
                                            </a>
                                        </MenuItem>
                                        <MenuItem>
                                            <a
                                                href="/technical-writers"
                                                className="block px-4 py-2 text-sm rmc-text-base-1 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:rmc-text-primary-2"
                                            >
                                                For technical writers
                                            </a>
                                        </MenuItem>
                                        {/* <MenuItem>
                                            <a
                                                href="/ai-content-writers"
                                                className="block px-4 py-2 text-sm rmc-text-base-1 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:rmc-text-primary-2"
                                            >
                                                For AI Content writers
                                            </a>
                                        </MenuItem> */}
                                    </div>
                                </MenuItems>
                            </Menu>
                            <Link
                                to={'/pricing'}
                                className={` font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2 ${
                                    currentPathname === '/pricing' ? 'rmc-text-primary-2' : ''
                                }`}
                            >
                                <Highlighter colored={false}>Pricing</Highlighter>
                            </Link>
                            <Link
                                to={'/blog'}
                                className={` font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2 ${
                                    currentPathname === '/blog' ? 'rmc-text-primary-2' : ''
                                }`}
                            >
                                <Highlighter colored={false}>Blog</Highlighter>
                            </Link>
                        </div>
                        <div className="hidden flex-1 lg:flex gap-4 rmc-text-base-1 justify-end">
                            <Link
                                to={signupUrl}
                                className="text-center text-white py-2 px-5 rmc-bg-base-1 hover:rmc-bg-base-2 rounded-lg font-medium"
                            >
                                Start 14-day free trial
                            </Link>
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-50" />
                        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                <a href="src/component/page#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">{companyName}</span>
                                    <img loading="lazy" className="h-8 w-auto" src={logoSmall} alt="small logo" />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        <Menu as={'div'} className="relative inline-block text-left">
                                            <MenuButton className="flex py-2 font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2">
                                                Product
                                                <img loading="lazy"  alt={''} src={'../arrow_drop_down.svg'} aria-hidden={true} />
                                            </MenuButton>

                                            <MenuItems
                                                anchor={'bottom start'}
                                                transition
                                                className="z-50 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                            >
                                                <div className="py-1 z-100">
                                                    <MenuItem>
                                                        <a
                                                            href="/"
                                                            className="mb-2 border-b-2 border-neutral-100 block px-4 py-2 font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2"
                                                        >
                                                            All-in-one analytics tool
                                                        </a>
                                                    </MenuItem>
                                                    <MenuItem>
                                                        <a
                                                            href="/bloggers"
                                                            className="block px-4 py-2  font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2"
                                                        >
                                                            For bloggers
                                                        </a>
                                                    </MenuItem>
                                                    <MenuItem>
                                                        <a
                                                            href="/technical-writers"
                                                            className="block px-4 py-2 font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2"
                                                        >
                                                            For technical writers
                                                        </a>
                                                    </MenuItem>
                                                    {/* <MenuItem>
                                                        <a
                                                            href="/ai-content-writers"
                                                            className="block px-4 py-2 font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2"
                                                        >
                                                            For AI Content writers
                                                        </a>
                                                    </MenuItem> */}
                                                </div>
                                            </MenuItems>
                                        </Menu>
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={`-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2 ${
                                                    currentPathname === item.href ? 'rmc-text-primary-2' : ''
                                                }`}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-6 space-y-2 flex flex-col gap-2">
                                        <a
                                            href={signinUrl}
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base  font-medium leading-6 rmc-text-base-1 hover:rmc-text-primary-2"
                                        >
                                            Log in
                                        </a>
                                        <Link
                                            to={signupUrl}
                                            className=" text-center text-white py-2 px-5 rmc-bg-primary-1 hover:rmc-bg-primary-3 rounded-lg font-medium w-full"
                                        >
                                            Start 14-day free trial
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>
            </div>
            <div className="mt-160 max-md:mt-4"></div>
        </>
    );
}
